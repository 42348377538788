/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useRef, useState, useEffect } from 'react';
import './LeadsMap.css';
import mapboxgl, { Map } from 'mapbox-gl';
import { SearchBox } from '@mapbox/search-js-react'
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Storefront } from '../../models/StorefrontResponse';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useZipCodeCounts } from "../../hooks/useZipCodeCounts";
import { ZipCodeCount } from '../../models/ZipCodeCountsResponse';
import * as turf from "@turf/turf";

interface CartProps {
  selectedZipChangedCallback(zip: string): void
  selectedRadiusChangedCallback(lat: number, lon: number): void
  storefront: Storefront
  uiMode: string
  radiusDistance: number
  zipCodeCountChangedCallback(zipCodeCount: ZipCodeCount): void
}

interface LocationHistory {
  lat: number
  lon: number
  timestamp: string
}

const LeadsMap = (props: CartProps) => {
  const [lng, setLng] = useState(-98.5795);
  const [lat, setLat] = useState(39.828175);
  const [storefront, setStorefront] = useState<Storefront>();
  const [mapboxAccessToken, setMapboxAccessToken] = useState("")
  const [tooltipIsOpen, setTooltipIsOpen] = useState(true);
  const [uiMode, setUIMode] = useState(props.uiMode)
  const [radiusDistance, setRadiusDistance] = useState(props.radiusDistance)
  const uiModeRef = React.useRef(uiMode)
  const radiusDistanceRef = React.useRef(radiusDistance)
  const currentPointRef = React.useRef([0, 0])

  const map = useRef<Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  let hoveredPolygonId: any = null;
  const [zipCodeSearch, setZipCodeSearch] = useState("")
  const { zipCodeCounts } = useZipCodeCounts(zipCodeSearch, "", "", "", false, 0, "");
  const FixedSearchBox = SearchBox as any;

  const boundarySoureLayer = "zip_boundaries_20240226"

  useEffect(() => {
    uiModeRef.current = uiMode

    // Set the radius distance to 0 when switching to zipcode mode so it doesn't show the circle
    if (uiMode == "zipcode") {
      setCircle(0)
    } else if (uiMode == "radius") {
      setCircle(radiusDistance)
    }
  }, [uiMode])

  useEffect(() => {
    radiusDistanceRef.current = radiusDistance

    if (uiMode == "radius") {
      setCircle(radiusDistance)
    }
  }, [radiusDistance])

  useEffect(() => {
    if (zipCodeCounts != undefined && zipCodeCounts.length > 0) {
      const lat: number = +zipCodeCounts[0].lat
      const lon: number = +zipCodeCounts[0].lng
      map.current?.flyTo({ center: [lon, lat], essential: true, zoom: 10 });

      props.zipCodeCountChangedCallback(zipCodeCounts[0])
    }
  }, [zipCodeCounts]);

  useEffect(() => {
    setUIMode(props.uiMode)
    setRadiusDistance(props.radiusDistance)
  }, [props])

  const saveLocation = (latitude: number, longitude: number) => {
    const locationHistory: LocationHistory = {
      lat: latitude,
      lon: longitude,
      timestamp: new Date().toISOString()
    }
    const locStr = JSON.stringify(locationHistory)
    localStorage.setItem('locationHistory', locStr)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const searchBoxRetrieved = (res: any) => {
    props.zipCodeCountChangedCallback({} as ZipCodeCount) // we did a non-specific search so send empty zip count to clear title
    if (res.type === 'FeatureCollection') {
      if (res.features.length > 0) {
        if (res.features[0].type === "Feature") {
          if (uiMode == "radius") {
            map.current?.flyTo({ center: [res.features[0].geometry.coordinates[0], res.features[0].geometry.coordinates[1]], essential: true, zoom: 18 });
          } else {
            map.current?.flyTo({ center: [res.features[0].geometry.coordinates[0], res.features[0].geometry.coordinates[1]], essential: true, zoom: 11.7 });
          }

          setLng(res.features[0].geometry.coordinates[0]);
          setLat(res.features[0].geometry.coordinates[1]);

          saveLocation(res.features[0].geometry.coordinates[1], res.features[0].geometry.coordinates[0])

          // If the search is a zip code, then set the zip code
          if (res.features[0].properties.feature_type === "postcode") {
            props.selectedZipChangedCallback(res.features[0].properties.name)
          }
        }
      }
    }
  }

  const setCircle = (distance: number) => {
    const _center = turf.point(currentPointRef.current);
    const _radius = distance;
    const _options = {
      steps: 80
    };

    const _circle = turf.circle(_center, _radius, _options);

    const radiusRangeSource = map.current?.getSource('radiusRange') as mapboxgl.GeoJSONSource;
    if (radiusRangeSource != undefined) {
      radiusRangeSource.setData(_circle)
    }
  }

  // const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: '#f5f5f9',
  //     color: 'rgba(0, 0, 0, 0.87)',
  //     maxWidth: 220,
  //     fontSize: theme.typography.pxToRem(12),
  //     border: '1px solid #dadde9',
  //   },
  // }));

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#059c1e",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#059c1e",
      fontSize: theme.typography.pxToRem(16),
    },
  }));

  useEffect(() => {
    setStorefront(props.storefront)
    const token = process.env.REACT_APP__MAPBOX_TOKEN;

    if (token != undefined) {
      setMapboxAccessToken(token);
      mapboxgl.accessToken = token;
    }
  })

  useEffect(() => {
    let zip = parseProduct()

    if (zip != undefined && zip != "") {
      console.log("Found zip in command line:", zip)

      if (zip.length == 4) {
        zip = "0" + zip
      }
      setZipCodeSearch(zip)
    } else {
      if (!('geolocation' in navigator)) {
        console.log("location services not available")
      } else {
        // Load location from local storage. If not available, then get location from browser.
        // If the location timestamp is older than 8 hours, then get location from browser.
        const locationHistoryStr = localStorage.getItem('locationHistory')
        let getGeoLocation = true
        if (locationHistoryStr != null) {
          const locationHistory: LocationHistory = JSON.parse(locationHistoryStr)
          const timestamp = new Date(locationHistory.timestamp)
          const now = new Date()
          const diff = now.getTime() - timestamp.getTime()
          const hours = diff / 1000 / 60 / 60

          if (hours < 8) {
            getGeoLocation = false
            setLat(locationHistory.lat)
            setLng(locationHistory.lon)

            // Delay the flyTo so the map is loaded
            setTimeout(() => {
              map.current?.jumpTo({ center: [locationHistory.lon, locationHistory.lat], zoom: 11.7 });
            }, 1000)

            //map.current?.flyTo({ center: [locationHistory.lon, locationHistory.lat], essential: true, zoom: 10 });

          } 
        } 
        
        if (getGeoLocation) {
          navigator.geolocation.getCurrentPosition(
            onSuccess,
            onError,
            { enableHighAccuracy: false, timeout: 10000, maximumAge: 18000000 },
          );
        }
      }
    }
  }, []);

  function parseProduct(): string {
    if (location.pathname == "/") {
      return ""
    }

    if (location.pathname == "/products" || location.pathname == "/products/") {
      return ""
    }

    return location.pathname.replace("/products/", "")
  }

  // mapbox://styles/danpenn/clirqto4000i201pu8jx4cz9v (Light Style)
  // mapbox://styles/danpenn/clq1wcjyt00la01r72k8917mv (Dark Style)
  useEffect(() => {
    let mapboxStyle = "mapbox://styles/danpenn/clirqto4000i201pu8jx4cz9v"
    const colorMode = localStorage.getItem('colorMode')
    if (colorMode == "dark") {
      mapboxStyle = "mapbox://styles/danpenn/clq1wcjyt00la01r72k8917mv"
    }

    console.log("Initializing Map...")
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainerRef.current as HTMLDivElement,
      style: mapboxStyle,
      center: [lng, lat],
      zoom: 3.5
    });

    map.current.on('load', function () {
      // The feature-state dependent fill-opacity expression will render the hover effect
      // when a feature's hover state is set to true.
      if (map.current == null) {
        return
      }

      const _center = turf.point([-122.0616526, 47.7275042]);
      const _radius = 0;
      const _options = {
        steps: 80
      };

      const _circle = turf.circle(_center, _radius, _options);

      map.current.addSource("radiusRange", {
        type: "geojson",
        data: _circle
      });

      map.current.addLayer({
        id: "circle-fill",
        type: "fill",
        source: "radiusRange",
        paint: {
          "fill-color": "#24fc03",
          "fill-opacity": .2,
        },
      });

      map.current.addSource("zips", {
        "type": "vector",
        "url": "mapbox://danpenn.b5k2x2yo"
      });

      map.current.addLayer({
        'id': 'zip-fills',
        'type': 'fill',
        'source': 'zips',
        'source-layer': boundarySoureLayer,
        'layout': {},
        'paint': {
          'fill-color': '#24fc03',
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.15,
            0
          ]
        }
      });
    });

    // When the user moves their mouse over the zip boundaries layer, we'll update the
    // feature state for the feature under the mouse.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    map.current.on('mousemove', 'zip-fills', function (e: any) {

      if (e.features == undefined || map.current == null) {
        return
      }

      if (e.features.length > 0) {
        if (hoveredPolygonId !== null) {
          map.current.setFeatureState(
            { source: 'zips', id: hoveredPolygonId, sourceLayer: boundarySoureLayer },
            { hover: false }
          );
        }

        if (uiModeRef.current == "zipcode") {
          hoveredPolygonId = e.features[0].id;
          map.current.setFeatureState(
            { source: 'zips', id: hoveredPolygonId, sourceLayer: boundarySoureLayer },
            { hover: true }
          );
        }
      }

    });

    // When the mouse leaves the state-fill layer, update the feature state of the
    // previously hovered feature.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    map.current.on('mouseleave', 'zip-fills', function (e: any) {
      if (e.features == undefined || map.current == null) {
        return
      }

      console.log("Mouse Leave")
      if (hoveredPolygonId !== null) {
        map.current.setFeatureState(
          { source: 'zips', id: hoveredPolygonId, sourceLayer: boundarySoureLayer },
          { hover: false }
        );
      }
      hoveredPolygonId = null;
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    map.current.on('click', 'zip-fills', function (e: any) {
      if (!e || !e.lngLat) {
        console.log("No lngLat")
        return
      }

      currentPointRef.current = [e.lngLat.lng, e.lngLat.lat]
      if (uiModeRef.current == "zipcode" || e.originalEvent.shiftKey) {
        setTooltipIsOpen(false)
        if (e.features == undefined || map.current == null) {
          return
        }
        // console.log("zip code:", e.features[0].properties.ZIP)
        //console.log(e.features)
        //setSelectedZip(e.features[0].properties.ZIP)
        props.selectedZipChangedCallback(e.features[0].properties.ZIP)
      } else if (uiModeRef.current == "radius") {
        setCircle(radiusDistanceRef.current)
        props.selectedRadiusChangedCallback(e.lngLat.lat, e.lngLat.lng)
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    map.current.on('click', 'counts', function (e: any) {
      console.log("counts clicked - UI Mode: ", props.uiMode, "Radius:", radiusDistanceRef.current)
      if (uiModeRef.current == "zipcode") {
        if (e.features == undefined || map.current == null) {
          return
        }

        props.selectedZipChangedCallback(e.features[0].properties.name)
        //console.log("zip code:", e.features[0].properties.name)
      }
    });

  }, [storefront, uiMode, radiusDistance]);

  const onSuccess = (location: GeolocationPosition) => {
    if (uiMode == "radius") {
      map.current?.flyTo({ center: [location.coords.longitude, location.coords.latitude], essential: true, zoom: 18 });
    } else {
      map.current?.flyTo({ center: [location.coords.longitude, location.coords.latitude], essential: true, zoom: 10 });
    }
    setLat(location.coords.latitude)
    setLng(location.coords.longitude)
  }

  const onError = (error: GeolocationPositionError) => {
    console.log("location error: ", error)
  }

  return (
    <div>
      <Grid sx={{ marginBottom: 1 }}>
        <Stack direction="row" spacing={2} sx={{ marginTop: -4 }} >
          <Box sx={{ minWidth: 300, width: 380, marginTop: -.75 }}>
            <form>
              <FixedSearchBox
                theme={{ variables: { unit: "16px", colorBackground: props.storefront.activeColors.mainPage.pageSearchBoxBackground, colorText: props.storefront.activeColors.mainPage.pageSearchBoxText, colorSecondary: '#bbbbc2' } }}
                accessToken={mapboxAccessToken}
                value=''
                options={{ language: 'en', country: 'US', }}
                onRetrieve={searchBoxRetrieved}
                onChange={() => setTooltipIsOpen(false)}
              />
            </form>
            <BootstrapTooltip title="Start Your Search Here" open={tooltipIsOpen} onClose={() => setTooltipIsOpen(false)} placement="bottom" PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -6],
                  },
                },
              ],
            }}>
              <Box></Box>
            </BootstrapTooltip>
          </Box>
        </Stack>
      </Grid>
      <Box ref={mapContainerRef} className="map-container" />
    </div>
  );
}

export default LeadsMap;
