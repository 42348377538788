import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Copyright from "../components/CopyRight";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import useAuth from "../hooks/useAuth";
import { useEffect } from "react";
import { ParseQueryString } from "../utils/apiService";
  
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

export default function forgotPasswordSubmit() {
  const navigate = useNavigate();
  const { forgotPasswordSubmit } = useAuth();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    code: Yup.string()
      .min(6, "Enter a valid confirmation code")
      .required("Confirmation code is required"),
    password: Yup.string()
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  useEffect(() => {
    // Log Pageview with Hubspot
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/forgotpasswordsubmit']);
    _hsq.push(['trackPageView']);
  }, []);

  const getLogoImage = () => {
    const queryParams = ParseQueryString(location.search)
    const sf = queryParams.get("sf")

    let storeName = "tsg"

    if (sf != undefined && sf != "" && sf != "tsg" ) {
      storeName = sf
    } else {
      const savedStorefront = localStorage.getItem("storefront")
      if (savedStorefront) {
        storeName = savedStorefront
      }
    }

    let baseURL = location.origin

    const colorMode = localStorage.getItem("colorMode")

    if (baseURL.indexOf("localhost")) {
      baseURL = "https://dev.portal.theshare.group"
    }

    let iconColorMode = "light"
    if (colorMode != undefined && colorMode == "dark") {
      iconColorMode = "dark"
    }

    console.log("logo selection: colorMode=", colorMode, ", baseURL=", baseURL, ", storefront=", storeName)
    
    const logoURL = baseURL + "/storefront/" + storeName + "_logo_" + iconColorMode + ".png"
    return logoURL
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={getLogoImage()} height={48} />
        <Typography component="h1" variant="h5" sx={{marginTop:2}}>
          Reset Password
        </Typography>
        <Typography sx={{marginTop: 2, marginBottom: 1}}>
          If the email address is associated with an account, a confirmation code will have been sent. Please enter the confirmation code below. Check your spam folder if you cannot find the message.
        </Typography>
        <Formik
          initialValues={{
            email: "",
            code: "",
            password: "",
            submit: null,
          }}
          validationSchema={validationSchema}
          onSubmit={async (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            values: any,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            { setErrors, setStatus, setSubmitting }: any
          ): Promise<void> => {
            try {
              setSubmitting(true);
              // user forgot password submit
              await forgotPasswordSubmit({username: values.email.toLowerCase(), code: values.code, password: values.password});
              navigate("/signin", { replace: true });
              setSubmitting(false);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (err: any) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }): JSX.Element => (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={values?.email}
                    onChange={handleChange}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="code"
                    label="Confirmation Code"
                    name="code"
                    value={values?.code}
                    onChange={handleChange}
                    error={Boolean(touched.code && errors.code)}
                    helperText={touched.code && errors.code}
                    autoComplete="code"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    value={values?.password}
                    onChange={handleChange}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>

                <Grid item xs={12}>
                  {/*Display Error with Icon*/}
                  {errors?.submit && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ErrorOutlineIcon sx={{ color: "red", mr: 1 }} />
                      <Typography variant="body2" color="error">
                        {errors?.submit}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Reset Password
                  </Button>
                </Grid>
              </Grid>
              <Typography
                sx={{
                  width: "100%",
                  my: 2,
                  textAlign: "center",
                }}
              >
                {/* OR */}
              </Typography>
            </Box>
          )}
        </Formik>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
