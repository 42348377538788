import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { CitiesResponse, City } from '../models/City';

export const useQueryCities = (searchCity: string) => {
    const [cities, setcities] = useState<City[]>()
    const [citiesError, setcitiesError] = useState("")
    const [citiesLoading, setcitiesLoading] = useState(false)
    
    useEffect(() => {
        if (searchCity == "") {
            return;
        }

        setcitiesLoading(true)
        setcitiesError("")
        setcities([])

        // Escape the url
        searchCity = encodeURIComponent(searchCity);
        const apiURL =  APIEndpoint(EndpointType.Query) + "/cities?city=" + searchCity;

        httpGet(apiURL)
            .then((data) => {
                const response = data as CitiesResponse;
                
                if (response.status == "error") {
                    setcitiesError(response.errorMessage)
                } else {
                    if (response.cities && response.cities.length > 0) {
                        const cities = response.cities as City[]
                        setcities(cities);
                    }
                }
            })
            .catch((error) => setcitiesError(error))
            .finally(() => setcitiesLoading(false))

    }, [searchCity]);

    return { cities, citiesLoading, citiesError };
};
