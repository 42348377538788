import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Title from "../components/Title";
import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { AutocompleteUser, useUsers } from "../hooks/useUsers";
import { useWalletBalance } from "../hooks/useWalletBalance";
import { useWalletTransactions } from "../hooks/useWalletTransactions";
import { WalletBalance } from "../models/Wallet";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingSpinner from "../components/LoadingSpinner";
import WalletTransactionList from "../components/WalletTransactionList";
import CopyToClipboard from "../components/CopyToClipboard";
import { useMyOrders } from "../hooks/useMyOrders";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import ErrorFallBack from "../components/ErrorFallBack";
import { format, parse } from 'date-format-parse';
import { Button, Link, Modal, Tooltip } from "@mui/material";
import { LocalTime } from "../utils/localTime";
import DownloadIcon from "@mui/icons-material/Download";
import OrderDetails from "../components/OrderDetails";
import { Storefront } from "../models/StorefrontResponse";
import { OutputFieldSetting } from "../models/UsersResponse";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

interface Props {
  storefront: Storefront
}

const Customers = (props: Props) => {

  const [balanceRefreshKey] = useState(0);
  const [customerID, setCustomerID] = useState("");
  const { users, autocompleteUsers } = useUsers(0);
  const { walletBalance } = useWalletBalance(customerID, balanceRefreshKey)
  const [transactionsRefreshKey] = useState(0);
  const { walletTransactions, walletTransactionsLoading } = useWalletTransactions(customerID, transactionsRefreshKey)
  const [userName, setUserName] = useState("")
  const [userCreatedDate, setUserCreatedDate] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [userPhone, setUserPhone] = useState("")
  const [userHubspotID, setUserHubspotID] = useState("")
  const [userID, setUserID] = useState("")
  const [userSF, setUserSF] = useState("")
  const [userMinOrder, setUserMinOrder] = useState("")
  const [userfeatures, setUserFeatures] = useState("")
  const [phone, setPhone] = useState("")
  const [id, setID] = useState("")
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [storefront, setSF] = useState("")
  const [features, setFeatures] = useState("")
  const [minorder, setMinOrder] = useState("")
  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const [orderDetailsID, setOrderDetailsID] = useState("");
  const [ignoreSuppressions, setIgnoreSuppressions] = useState(false);
  const [outputFields, setOutputFields] = useState<OutputFieldSetting[]>([]);

  const columns: GridColDef[] = [
    {
      field: 'id', headerName: 'ID', width: 147, renderCell: (params: GridRenderCellParams) => {
        return <Link
          component="button"
          variant="body2"
          onClick={() => {
            if (params.value != "TSG-20240420-SMPL") {
              setOrderDetailsID(params.value);
              setOpenOrderDetails(true);
            }
          }}
        >
          {params.value}
        </Link>
      }
    },
    {
      field: 'createdAt', headerName: 'Date', width: 85, renderCell: (params: GridRenderCellParams) => {
        return <div>
          <Tooltip title={LocalTime(params.value)} placement="bottom-start">
            <Typography variant="body2">{getShortDate(params.value)}</Typography>
          </Tooltip>
        </div>
      }
    },
    {
      field: 'totalPrice', headerName: 'Amount', width: 100, valueGetter: (params: GridValueGetterParams) => {
        return formatter.format(params.value);
      },
    },
    { field: 'description', headerName: 'Description', width: 150 },
    { field: 'hubSpotDealID', headerName: 'HubSpot Deal ID', width: 150 },
    {
      field: 'fulfillment', headerName: 'Download', width: 80, renderCell: (params: GridRenderCellParams) => {
        if (params.value.downloadURL == "na") {
          return <div></div>
        } else if (params.value.downloadURL != "") {
          return <Button href={`${params.value.downloadURL}`} download={"TSG Order"} size="small" >
            <DownloadIcon color="primary" />
          </Button>
        } else {
          return <div>Pending...</div>
        }
      },
    },
  ]


  const { orders, loading, ordersError } = useMyOrders(customerID, balanceRefreshKey);


  const customerIDChanged = (event: React.SyntheticEvent<Element, Event>, value: AutocompleteUser | null) => {
    if (value == null) {
      setCustomerID("")
    } else {
      setCustomerID(value.id)

      for (const user of users) {
        if (user.id == value.id) {
          setUserID(user.id)
          setUserName(user.firstName + ' ' + user.lastName)
          setUserEmail(user.email)
          setUserPhone(user.phone)
          setUserSF(user.storefrontName)
          setUserMinOrder(user.settings.minOrderAmount.toString())
          setID('Customer ID:')
          setName('Name:')
          setEmail('Email:')
          setPhone('Phone:')
          setSF('StoreFront:')
          setMinOrder('Min Order:')
          setUserCreatedDate(user.createdAt)
          setUserHubspotID(user.hubspotCustomerID)
          setIgnoreSuppressions(user.settings.ignoreSuppressions)
          setOutputFields(user.settings.outputFieldSettings)

          if (user.features != null) {
            setUserFeatures(user.features.toString())
            setFeatures('Features:')
          }
        }
      }
    }
  }

  function getShortDate(dateString: string | undefined) {
    if (dateString != undefined) {
      const dt = parse(dateString, 'YYYY-MM-DDTHH:mm:ss');
      return format(dt, "MM/DD/YY")
    }
  }

  const getWalletBalance = (wb: WalletBalance | undefined) => {
    if (wb != undefined && wb.balance != undefined) {
      return wb.balance.toLocaleString() + " leads"
    }

    return "0 leads"
  }


  useEffect(() => {
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(["setPath", "/Customers"]);
    _hsq.push(["trackPageView"]);
  }, []);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const orderDetailsStyle = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: props.storefront.activeColors.addProductForm.formBackground,
    border: '1px solid #5c5c5e',
    boxShadow: 24,
    p: 1,
  };

  const handleOrderDetailsClose = () => setOpenOrderDetails(false);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Title>Customers</Title>
            <Box sx={{ width: 600, marginTop: 2 }}>
              <Autocomplete
                disablePortal
                isOptionEqualToValue={(
                  option: AutocompleteUser,
                  value: AutocompleteUser
                ) => option.id === value.id}
                id="existingCustomer"
                onChange={customerIDChanged}
                options={autocompleteUsers}
                size="small"
                sx={{ width: 360 }}
                renderInput={(params) => (
                  <TextField {...params} label="Customer Name" />

                )}

              />

            </Box>

            {customerID != "" &&
              <Grid sx={{ marginTop: 2 }} >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography sx={{ width: 200, fontWeight: "bold" }}>{id}</Typography>
                  <Typography sx={{}}>{userID} <CopyToClipboard value={userID}></CopyToClipboard> </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography sx={{ width: 200, fontWeight: "bold" }}>Date Created:</Typography>
                  <Typography sx={{}}>{getShortDate(userCreatedDate)}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography sx={{ width: 200, fontWeight: "bold" }}>{name}</Typography>
                  <Typography sx={{}}>{userName}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography sx={{ width: 200, fontWeight: "bold" }}>{phone}</Typography>
                  <Typography sx={{}}>{userPhone}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography sx={{ width: 200, fontWeight: "bold" }}>{email}</Typography>
                  <Typography sx={{}}>{userEmail} <CopyToClipboard value={userEmail} /></Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography sx={{ width: 200, fontWeight: "bold" }}>{storefront}</Typography>
                  <Typography sx={{}}>{userSF}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography sx={{ width: 200, fontWeight: "bold" }}>{minorder}</Typography>
                  <Typography sx={{}}>{userMinOrder}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography sx={{ width: 200, fontWeight: "bold" }}>{features}</Typography>
                  <Typography sx={{}}>{userfeatures}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography sx={{ width: 200, fontWeight: "bold" }}>HubSpot Customer ID</Typography>
                  <Typography sx={{}}>{userHubspotID}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography sx={{ width: 200, fontWeight: "bold" }}>Suppress Previous Orders</Typography>
                  <Typography>{ignoreSuppressions?"True":"False"}</Typography>
                </Stack>
                {outputFields && outputFields.length > 0 &&
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography sx={{ width: 200, fontWeight: "bold" }}>Custom Output Fields</Typography>
                  <Stack direction="column" alignItems="center" spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      {outputFields.map((field) => {
                        return <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography sx={{ }}>DataSource #{field.dataSourceID}:</Typography>
                          <Typography>{field.outputFields.join(",")}</Typography>
                        </Stack>
                      })}
                    </Stack>
                  </Stack>
                  </Stack>
                }
              </Grid>
            }

            <Box sx={{ marginTop: 2 }}>
              <Stack direction="row" spacing={2}>
                <Typography sx={{ width: 200, fontWeight: "bold" }}>Wallet Balance:</Typography>
                <Typography>{getWalletBalance(walletBalance)}</Typography>
              </Stack>
            </Box>

            { }

            <Grid marginTop={4} height={300}>
              <Typography sx={{ fontWeight: "bold" }}>Customer Orders:</Typography>
              {loading
                ? <LoadingSpinner title="Loading Orders..." textColor="#0352fc" />
                : ordersError
                  ? <ErrorFallBack message={ordersError} />
                  :
                  <DataGridPremium
                    rows={orders}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                      },
                      sorting: {
                        sortModel: [{ field: 'id', sort: 'desc' }],
                      },
                    }}
                    pageSizeOptions={[5, 10, 25, 50]}

                  />
              }
            </Grid>

            <Box sx={{ marginTop: 4, minWidth: 460 }}>
              <Stack direction="column" spacing={0}>
                <Typography sx={{ fontWeight: "bold", mt: 2 }}>Wallet Transactions:</Typography>
                {walletTransactionsLoading
                  ? <LoadingSpinner title="Loading wallet..." textColor="#0352fc" />
                  : <WalletTransactionList transactions={walletTransactions} height={300} />
                }
              </Stack>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Modal
        open={openOrderDetails}
        onClose={handleOrderDetailsClose}
      >
        <Box sx={orderDetailsStyle}>
          <OrderDetails orderID={orderDetailsID} showFullDetails close={() => { setOpenOrderDetails(false) }} />
        </Box>
      </Modal>
    </Container>
  );
};

export default Customers;
