import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TSGLogoAllWhite from "../assets/images/tsg_logo_all_white.png"
import TSGLogoAllBlack from "../assets/images/tsg_logo_all_black.png"
import PoweredByTSGDark from "../assets/images/tsglogo_poweredby_dark.png"
import PoweredByTSGLight from "../assets/images/tsglogo_poweredby_light.png"
import { useState, useEffect } from "react";
import Drawer from '@mui/material/Drawer';
import { IconButton } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ClickAwayListener } from '@mui/base';


interface FooterProps {
    position: string
    storefrontName: string
}

export default function Footer(props: FooterProps) {
    const [linkClass, setLinkClass] = useState("link_lightmode");
    const [logo, setLogo] = useState(TSGLogoAllBlack);
    const [showFooter, setShowFooter] = useState(false);

    useEffect(() => {
        const colorMode = localStorage.getItem('colorMode')
        if (colorMode == "dark") {
            setLinkClass("link_darkmode")
        } else {
            setLinkClass("link_lightmode")
        }

        if (props.storefrontName === '' || props.storefrontName === 'tsg') {
            if (colorMode == "dark") {
                setLogo(TSGLogoAllWhite)
            } else {
                setLogo(TSGLogoAllBlack)
            }
        } else {
            if (colorMode == "dark") {
                setLogo(PoweredByTSGDark) 
            } else {
                setLogo(PoweredByTSGLight)
            }
        }
    }, );

    const handleClickAway = () => {
        
    };

    function handleClose() { 
        setShowFooter(false)
    }

  return (
    <Box sx={{
        marginTop: 'calc(0% + 0px)',
        width: "70%",
        position: props.position,
        bottom: 0,
    }} component="footer">
        <Stack>
            <Stack direction="row" sx={{}}>
                <Box sx={{marginTop:2, marginBottom:1, marginLeft:10}}>
                    <img src={logo} height={40} alt="Logo" />
                </Box>
                <IconButton onClick={() => setShowFooter(!showFooter)} sx={{marginLeft:0, height:40}}>
                    <ExpandLessIcon />
                </IconButton>
            </Stack>
            <ClickAwayListener onClickAway={handleClickAway} >
                <Drawer open={showFooter} anchor="bottom" onClose={handleClose}> 
                    <Stack direction="row" justifyContent="space-between" sx={{marginBottom:1}}>
                        <Box sx={{marginLeft:11}}>
                            <Stack>
                                <Stack direction="row">
                                    <Box sx={{marginTop:2, marginBottom:2}}>
                                        <img src={logo} height={40} alt="Logo" />
                                    </Box>
                                    <IconButton onClick={() => setShowFooter(!showFooter)} sx={{marginLeft:2, height:40}}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </Stack>
                                <Stack direction="column">
                                    <Stack direction="row">
                                        <Typography sx={{fontWeight:"bold", marginRight:1}}>Web:</Typography>
                                        <Box>
                                            <a href="https://www.theshare.group/" className={linkClass} target="_blank">www.theshare.group</a>
                                        </Box>
                                    </Stack>
                                    <Stack direction="row">
                                        <Typography sx={{fontWeight:"bold", marginRight:1}}>Email:</Typography>
                                        <Box>
                                            <a href="mailto:info@theshare.group" className={linkClass} target="_blank">info@theshare.group</a>
                                        </Box>
                                    </Stack>
                                    <Stack direction="row">
                                        <Typography sx={{fontWeight:"bold", marginRight:1}}>Phone:</Typography>
                                        <Box>
                                            <a href="tel:4022354556" className={linkClass} target="_blank">402-235-4556</a>
                                        </Box>
                                    </Stack>   
                                    <Stack direction="row">
                                        <Typography sx={{fontWeight:"bold", marginRight:1}}>Address:</Typography>
                                        <Typography>950 S 10th St. Suite 29, Omaha, NE 68108</Typography>
                                    </Stack>             
                                </Stack>
                            </Stack>
                        </Box>

                        <Box sx={{marginRight:5}}>
                            <Stack direction="row" spacing={10} sx={{marginTop:5}}>
                                <Stack>
                                    <Box>
                                        <a href="https://www.theshare.group/" className={linkClass} target="_blank">Home</a>
                                    </Box>
                                    <Box>
                                        <a href="https://www.theshare.group/contact" className={linkClass} target="_blank">Contact Us</a>
                                    </Box>
                                    <Box>
                                        <a href="https://www.theshare.group/help" className={linkClass} target="_blank">FAQs</a>
                                    </Box>
                                    <Box>
                                        <a href="https://www.theshare.group/help/how-do-i-download-my-leads" className={linkClass} target="_blank">How to Download Leads</a>
                                    </Box>
                                </Stack>
                                <Stack>
                                    <Box>
                                        <a href="https://www.theshare.group/meeting" className={linkClass} target="_blank">Schedule a Meeting</a>
                                    </Box>
                                    <Box>
                                        <a href="https://www.theshare.group/about" className={linkClass} target="_blank">About Us</a>
                                    </Box>
                                    <Box>
                                        <a href="https://www.theshare.group/terms" className={linkClass} target="_blank">Terms of Service</a>
                                    </Box>
                                    <Box>
                                        <a href="https://www.theshare.group/privacy" className={linkClass} target="_blank">Privacy Policy</a>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>
                </Drawer>
            </ClickAwayListener>
      </Stack>
    </Box>
  );
}
