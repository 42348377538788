import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useQueryCities } from '../hooks/useQueryCities';
import { Alert, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { Storefront } from '../models/StorefrontResponse';
import SearchIcon from '@mui/icons-material/Search';
import { City } from '../models/City';

interface Props {
    city: string
    storefront: Storefront
    citySelectedCallback(city: City): void
    closeDialogCallback(): void
}

export default function CitySearchDialog(props: Props) {
    const [citySearch, setCitySearch] = React.useState<string>("")
    const [citySearchValue, setCitySearchValue] = React.useState<string>("")
    const [selectedCityID, setSelectedCityID] = React.useState<string>("")
    const { cities, citiesError, citiesLoading } = useQueryCities(citySearchValue)

    React.useEffect(() => {
        setSelectedCityID(cities && cities?.length > 0 ? cities[0].id.toString() : "")
    }, [cities])

    const handleDialogClose = () => {
        props.closeDialogCallback()
    };

    const citySearchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCitySearch(event.target.value)
    }

    const handleSelectValueChange = (event: SelectChangeEvent) => {
        setSelectedCityID(event.target.value as string)
    }

    const doSearch = () => {
        setCitySearchValue(citySearch)
    }

    const selectCity = () => {
        // Find the selected city in the cities array
        const city = cities?.find((city) => city.id == parseInt(selectedCityID))
        if (city) {
            props.citySelectedCallback(city)
        } else {
            console.error("City not found in search results")
        }
    }

    return (
        <Stack spacing={2} sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    Search for a City
                </Typography>
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => handleDialogClose()}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Box>
            <Stack direction="row" spacing={2}>
                <TextField sx={{width:300}} id="citySearch" label="City" variant="outlined" value={citySearch} onChange={citySearchChanged}></TextField>
                <Button size="small" aria-label="search" variant="contained" onClick={() => doSearch()}startIcon={<SearchIcon />} sx={{width:96, height:54}}>Search</Button>
            </Stack>
            {citiesLoading && <CircularProgress size="20px" />}
            {citiesError && <Alert severity="error">Failed to search for '{citySearchValue}'</Alert>}
            {cities && cities.length > 0 ?
                <Stack direction="row" spacing={2}>
                <FormControl fullWidth size="small" sx={{ width: 300, mt:2 }}>
                    <InputLabel>Search Results</InputLabel>
                    <Select label="Search Results" onChange={handleSelectValueChange} size="medium"  value={selectedCityID} MenuProps={{
                        sx: {
                            "&& .Mui-selected": {
                                backgroundColor: props.storefront.activeColors.leftMenu.icon
                            }
                        }
                    }}>
                        {cities.map((item) => (
                            <MenuItem value={item.id} key={item.id}>{item.city_state}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button size="medium" aria-label="search" variant="contained" onClick={selectCity} sx={{width:96, height:54}}>Select</Button>
                </Stack>
                : <Alert severity="info">No cities found matching '{citySearchValue}'</Alert>
            }
        </Stack>

    );
}
