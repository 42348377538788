import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import { CartItem, ShoppingCart, SuppressionCheck } from '../models/Store';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from "@mui/material/Tooltip";
import { useState, useEffect } from 'react';
import { Storefront } from '../models/StorefrontResponse';
import CircularProgress from '@mui/material/CircularProgress';
import { Product } from '../models/Products';
import InfoIcon from "@mui/icons-material/Info";
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const Product_Name_NoDNC = "Do-Not-Call Removal"
const Product_Name_PhonesEmails = "Missing Email Removal"
const Product_Name_EmailsNoDNC = "Do-Not-Call/Missing Email Removal"

interface ShoppingCartProps {
  cart: ShoppingCart
  storefront: Storefront
  minOrderAmount: number
  walletBalance: number
  canCheckout: boolean
  removeItemCallback(productID: string, productType: string, zip: string): void
  applyWalletCredits(apply: boolean): void
  checkoutCallback(): void
  closeCartCallback(): void
  emptyCartCallback(): void
}

export default function ShoppingCartContainer(props: ShoppingCartProps) {
  const [walletApplied, setWalletApplied] = useState(true)
  const [ShowSuppressionDialog, setShowSuppressionDialog] = useState(false);

  useEffect(() => {
    // if (location.pathname === '/leadsstore' || location.pathname === '/') {
    //   setMarginTop(8)
    // }
    calcLeadCount()
  }, []);

  useEffect(() => {
    if (props.cart.applyWallet) {
      setWalletApplied(props.cart.applyWallet)
    } else {
      console.log("changing wallet applied checkbox to false since it is not defined")
      setWalletApplied(false)
    }

  }, [props.walletBalance, props.cart]);

  useEffect(() => {
    props.applyWalletCredits(walletApplied)
  }, [walletApplied]);

  const handleRemoveItemClick = (cartItem: CartItem, zip: string) => {
    props.removeItemCallback(cartItem.id, cartItem.product.productType, zip)
  };

  const handleCheckoutClick = () => {
    props.checkoutCallback()
  };

  const handleCloseCartClick = () => {
    console.log("button background: " + props.storefront.activeColors.cart.cartButtonBackground);
    props.closeCartCallback()
  };

  const handleEmptyCartClick = () => {
    props.emptyCartCallback()
  };

  const handleApplyWalletChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWalletApplied(event.target.checked);
  };

  const getCreditsRemaining = (): number => {
    return props.walletBalance - props.cart.walletCreditsApplied
  }

  function getLeadTypeFriendlyName(product: Product): string {
    if (product == undefined || product.leadType == undefined) {
      return ""
    }

    switch (product.leadType) {
      case "absentee":
        return "Absentee";
      case "distressed":
        return "Distressed";
      case "divorced":
        return "Divorced";
      case "downsizer":
        return "Downsizer";
      case "homesPhones":
        return "All Homes with Phones"
      case "likelyToSell":
        return "Likely-to-Sell"
    }

    if (product.leadType.indexOf("distressed") >= 0) {
      return "Distressed"
    }

    if (product.leadType.indexOf("downsizer") >= 0) {
      return "Downsizer"
    }

    if (product.leadType.indexOf("divorced") >= 0) {
      return "Divorced"
    }

    if (product.leadType.indexOf("absentee") >= 0) {
      return "Absentee"
    }

    if (product.leadType.indexOf("homesPhones") >= 0) {
      return "All Homes with Phones"
    }

    if (product.leadType.indexOf("likelyToSell") >= 0) {
      return "Likely-to-Sell"
    }

    return ""
  }

  function calcLeadCount() {
    if (props.cart === undefined) {
      return 0
    }

    let leadCount = 0
    for (const item of props.cart.items) {
      if (item.product == undefined) {
        continue
      }

      // If there is a subscription in the cart then there is no minimum
      if (item.product.productType === 'subscription') {
        leadCount += 500
      }

      if (item.product.productType === 'leads' || item.product.productType === 'investor_leads') {
        leadCount += item.quantity
      }
    }

    return leadCount
  }

  function orderProgress(): number {
    if (props.cart === undefined) {
      return 0
    }

    // If a wallet is being used then there is no minimum
    if (props.cart.applyWallet) {
      if (props.cart.walletCreditsApplied > 0 || props.walletBalance > 0) {
        return 100
      }
    }

    let price = 0

    for (const item of props.cart.items) {
      if (item.product == undefined) {
        continue
      }

      // If there is a subscription in the cart then there is no minimum
      if (item.product.productType === 'subscription') {
        return 100
      }

      if (item.product.productType === 'leads' || item.product.productType === 'investor_leads') {
        price += item.product.priceInfo.price * item.quantity
      }
    }

    // 
    let progress = price / props.minOrderAmount
    if (progress > 1) {
      progress = 1
    }

    return Math.round(progress * 100)
  }

  // getLeadsShort determines the number of leads remaining in order to get to the minimum order amount
  function getLeadsShort(): number {
    if (props.cart === undefined) {
      return 1500
    }

    let price = 0
    for (const item of props.cart.items) {
      if (item.product != undefined && (item.product.productType === 'leads' || item.product.productType === 'investor_leads')) {
        price += item.product.priceInfo.price * item.quantity
      }
    }

    const remainingUSD = props.minOrderAmount - price
    if (remainingUSD <= 0) {
      return 0
    }

    return Math.round(remainingUSD / .2)
  }

  const hasProcessing = (product: Product): boolean => {
    if (product.features.noDNC || product.features.phonesEmails) {
      return true
    }

    return false
  }

  const processingDescription = (product: Product): string => {
    if (product.features.noDNC && product.features.phonesEmails) {
      return Product_Name_EmailsNoDNC
    } else if (product.features.noDNC) {
      return Product_Name_NoDNC
    } else if (product.features.phonesEmails) {
      return Product_Name_PhonesEmails
    }

    return ""
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const getSuppressionTooltip = (suppressionCheck: SuppressionCheck): string => {
    const tooltip = "The quantity for this item was automatically reduced from " + suppressionCheck.requestedCount + " to " + suppressionCheck.availableCount + " because fewer leads are available. Possible causes of this are previous orders have already provided these leads or data availability may have changed."

    return tooltip
  }

  const getRadiusDisplay = (zip4Radius: string | undefined): string => {
    // "92203+1651-0.2"
    if (zip4Radius == undefined) {
      return "Radius TBD"
    }

    const parts = zip4Radius.split("-")
    if (parts.length == 2) {
      return (parts[0] + " (" + parts[1] + " miles)").replace("+", ":")
    }

    return zip4Radius
  }

  const handleSuppressionDialogClose = () => {
    setShowSuppressionDialog(false);
  };
  
  return (
    <React.Fragment>
      <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ marginTop: 8 }}>
        <Typography sx={{ ml: 1, fontWeight: 'bold', fontSize: 20, color: props.storefront.activeColors.cart.cartTitleText }}>Shopping Cart</Typography>
        <IconButton sx={{ marginTop: -.5, color: props.storefront.activeColors.cart.cartProductRemoveIcon }} size="small" type="button" onClick={() => handleEmptyCartClick()} >
          <RemoveShoppingCartIcon />
        </IconButton>
        <IconButton sx={{ marginTop: -.5, color: props.storefront.activeColors.cart.cartIconBackground }} size="small" type="button" onClick={() => handleCloseCartClick()} >
          <CloseIcon />
        </IconButton>
      </Stack>
      {props.cart.items.length === 0
        ? <Box sx={{ marginLeft: 1, marginBottom: 2, color: props.storefront.activeColors.cart.cartTitleText }}>There are no products in the cart!</Box>
        : <Box></Box>
      }
      <Grid sx={{ paddingLeft: 1, paddingRight: 1, paddingBottom: 2 }}>
        <Stack direction="column" spacing={1}>
          {orderProgress() < 100
            ? <Paper sx={{ backgroundColor: props.storefront.activeColors.cart.cartPurchaseProgressBackground, fontSize: 16, color: props.storefront.activeColors.cart.cartProductText }}>
              <Stack direction="column" sx={{ marginLeft: 1 }}>
                <Box sx={{ fontWeight: "bold" }}>Purchase Progress</Box>
                <Box sx={{ fontSize: 12 }}>Individual leads have a {formatter.format(props.minOrderAmount)} minimum order</Box>
                <Stack direction='row' justifyContent="space-evenly">
                  <Box sx={{ position: 'relative', display: 'inline-flex', marginBottom: 1 }}>
                    <CircularProgress variant="determinate" value={orderProgress()} sx={{ marginLeft: 0 }} />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        variant="caption"
                        component="div"
                      >{`${orderProgress()}%`}</Typography>
                    </Box>
                  </Box>
                  <Stack alignItems="center">
                    <Box sx={{ fontSize: 14, marginTop: 1 }}>{getLeadsShort()} lead to go</Box>
                  </Stack>
                </Stack>


              </Stack>
            </Paper>
            : <div />
          }

          {props.cart.items.map((row) => (
            <Paper key={row.id} sx={{ marginBottom: 1, backgroundColor: props.storefront.activeColors.cart.cartProductBackground, fontSize: 14, color: props.storefront.activeColors.cart.cartProductText }}>
              <Stack direction="column">
                {row.product != undefined && (row.product.productType === 'leads' || row.product.productType === 'investor_leads')
                  ? <Box sx={{ marginLeft: 1, marginBottom: -1 }}>
                    <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                      {row.postalCodes && row.postalCodes.length > 0
                        ? <Box sx={{ fontSize: 15 }}>Zip Code: {row.postalCodes[0]}</Box>
                        : <Box sx={{ fontSize: 15 }}>Radius: {getRadiusDisplay(row.radiusSettings?.zip4Radius)}</Box>
                      }
                      <IconButton sx={{ marginTop: -.5, color: props.storefront.activeColors.cart.cartProductRemoveIcon }} size="small" type="button" onClick={() => handleRemoveItemClick(row, '')} >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Stack>
                  </Box>
                  : row.product.productType === 'subscription'
                    ? <Box sx={{ marginLeft: 1 }}>
                      <Box sx={{ fontSize: 15 }}>{row.product.name} Subscription</Box>
                    </Box>
                    : row.product.productType === 'processing'
                      ? <Box sx={{ marginLeft: 1 }}>
                        <Box sx={{ fontSize: 15 }}>Advanced Options</Box>
                      </Box>
                      : <Box />
                }

                {row.product != undefined && (row.product.productType === 'leads' || row.product.productType === 'investor_leads')
                  ?
                  <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ marginLeft: 2, marginRight: 1 }}>
                    <Box>{getLeadTypeFriendlyName(row.product)} Leads:</Box>
                    <Box>{row.quantity.toLocaleString()}</Box>
                  </Stack>
                  : row.product.productType === 'subscription'
                    ? <Box>
                      {row.postalCodes.map((zip) => (
                        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ marginLeft: 2, marginBottom: -1 }} key={zip}>
                          <Box>{zip}</Box>
                          <Box sx={{ marginTop: -.5 }}>
                            <IconButton size="small" type="button" sx={{ color: props.storefront.activeColors.cart.cartProductRemoveIcon }} onClick={() => handleRemoveItemClick(row, zip)} >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Box>
                        </Stack>
                      ))}
                    </Box>
                    : <Box sx={{ ml: 2 }}>{row.product.name}</Box>
                }

                {row.product != undefined && hasProcessing(row.product) &&
                  <Box sx={{ ml: 2 }}>{processingDescription(row.product)}</Box>
                }

                {row.suppressionCheck && row.suppressionCheck.checkCompleted && !row.suppressionCheck.isCountFulfilled &&
                  <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ marginRight: 1, color: "#D32F2F" }}>
                    <Stack direction="row" alignItems="center">
                      <Box sx={{ ml: 2 }}>Adjustment:</Box>
                      <Tooltip title={getSuppressionTooltip(row.suppressionCheck)}>
                        <InfoIcon color="error" sx={{ height: 16 }} />
                      </Tooltip>
                    </Stack>
                    <Box>{(row.suppressionCheck.requestedCount - row.suppressionCheck.availableCount)}</Box>
                  </Stack>
                }

                {row.product != undefined && (row.product.productType === 'leads' || row.product.productType === 'investor_leads')
                  ?
                  <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ marginLeft: 1, marginRight: 1, fontWeight: "normal" }}>
                    <Box>Price:</Box>
                    <Box>{formatter.format(row.product.priceInfo.price * row.quantity)}</Box>
                  </Stack>
                  :
                  <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ marginLeft: 1, marginRight: 1, fontWeight: "normal" }}>
                    <Box>Price:</Box>
                    <Box>{formatter.format(row.product.priceInfo.price)}</Box>
                  </Stack>
                }
              </Stack>
            </Paper>
          ))}

          <Paper sx={{ backgroundColor: props.storefront.activeColors.cart.cartPurchaseTotalBackground, fontSize: 16 }}>
            <Box>
              <Stack direction="column" sx={{ marginLeft: 1 }}>
                <Box sx={{ fontWeight: "bold", color: props.storefront.activeColors.cart.cartPurchaseText }}>Purchase Total</Box>

                <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ marginRight: 1 }}>
                  <Box sx={{ color: props.storefront.activeColors.cart.cartPurchaseText }}>Lead Count</Box>
                  <Box sx={{ color: props.storefront.activeColors.cart.cartPurchaseText }}>{calcLeadCount().toLocaleString()}</Box>
                </Stack>

                <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ marginRight: 1 }}>
                  <Box sx={{ color: props.storefront.activeColors.cart.cartPurchaseText }}>Subtotal</Box>
                  <Box sx={{ color: props.storefront.activeColors.cart.cartPurchaseText }}>{formatter.format(props.cart.subtotal)} USD</Box>
                </Stack>
                <Divider variant='fullWidth' sx={{ paddingTop: 1, marginRight: 1, marginBottom: 1 }} />

                {props.walletBalance > 0 &&
                  <Box>
                    <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ marginRight: 1 }}>
                      <Box sx={{ color: props.storefront.activeColors.cart.cartPurchaseText }}>Wallet Credit Balance</Box>
                      <Box sx={{ color: props.storefront.activeColors.cart.cartPurchaseText }}>{props.walletBalance}</Box>
                    </Stack>
                    <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ marginRight: 1 }}>
                      <FormControlLabel control={<Checkbox checked={walletApplied} onChange={handleApplyWalletChange} sx={{ color: props.storefront.activeColors.cart.cartProductText }} />} label="Use Wallet" sx={{ color: props.storefront.activeColors.cart.cartPurchaseText, mt: -1 }} />
                      <Box sx={{ color: props.storefront.activeColors.cart.cartPurchaseText }}>{props.cart.walletCreditsApplied}</Box>
                    </Stack>
                    <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ marginRight: 1, mt: -1 }}>
                      <Box sx={{ color: props.storefront.activeColors.cart.cartPurchaseText }}>Wallet Credits Remaining</Box>
                      <Box sx={{ color: props.storefront.activeColors.cart.cartPurchaseText }}>{getCreditsRemaining()}</Box>
                    </Stack>
                    <Divider variant='fullWidth' sx={{ paddingTop: 1, marginRight: 1, marginBottom: 1 }} />
                  </Box>
                }

                <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ marginRight: 1 }}>
                  <Box sx={{ color: props.storefront.activeColors.cart.cartPurchaseText }}>Total</Box>
                  <Box sx={{ color: props.storefront.activeColors.cart.cartPurchaseText }}>{formatter.format(props.cart.total)} USD</Box>
                </Stack>

                <Box display="flex" alignItems="center" justifyContent="center">
                  {!props.canCheckout
                    ? <Button size='small' variant='contained' onClick={() => setShowSuppressionDialog(true)} sx={{ width: 200, marginBottom: 2, marginTop: 1, backgroundColor: props.storefront.activeColors.cart.cartButtonBackground, color: props.storefront.activeColors.addProductForm.buttonText }}>Verifying Counts...<CircularProgress size="20px" sx={{ ml: 1 }} /></Button>
                    : <Button size='small' variant='contained' onClick={handleCheckoutClick} sx={{ width: 200, marginBottom: 2, marginTop: 1, backgroundColor: props.storefront.activeColors.cart.cartButtonBackground, color: props.storefront.activeColors.addProductForm.buttonText }}>Proceed to checkout</Button>
                  }
                </Box>
              </Stack>
            </Box>
          </Paper>
        </Stack>
      </Grid>
      <Dialog
        open={ShowSuppressionDialog}
        onClose={handleSuppressionDialogClose}
      >
        <DialogTitle>Verifying Lead Counts</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            We are verifying the lead counts for the products in your cart. This includes checking any past orders to ensure you are only purchasing new leads.
          </DialogContentText>
          <DialogContentText sx={{mt:1}} id="alert-dialog-slide-description">
            This process should not take more than a minute.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuppressionDialogClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}