import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import * as React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import Title from '../components/Title';
import LoadingSpinner from "../components/LoadingSpinner";
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import ErrorFallBack from "../components/ErrorFallBack";
import { DataGridPremium, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import { Storefront } from "../models/StorefrontResponse";
import Modal from '@mui/material/Modal';
import { useSuppressions } from "../hooks/useSuppressions";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import NoData from "../components/NoData";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { APIEndpoint, EndpointType, httpDelete, httpPost } from "../utils/apiService";
import { SuppressionListResponse, SuppressionListStatus } from "../models/Suppression";
import { Alert, FormControl, TextField, Tooltip, Typography } from "@mui/material";
import SuppressionUpload2 from "../components/SuppressionUpload2";
import {SuppressionList} from "../models/Suppression";
import { useTSGUser } from "../contexts/TSGUserContext";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

interface Props {
    storefront: Storefront
    isTSGUser: boolean
}

const Suppressions = (props: Props) => {
    const [refreshKey, setRefreshKey] = useState(0);
    const [openSuppressionUpload, setOpenSuppressionUpload] = useState(false);
    const [warningMessage, setWarningMessage] = useState("")
    const [vaultID, setVaultID] = useState<string>("")

    // Hooks
    const { tsgUser } = useTSGUser();
    const { suppressions, suppressionsLoading, suppressionsError } = useSuppressions(tsgUser?.id || "", refreshKey);

    useEffect(() => {
        // Log Pageview with Hubspot
        const _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', '/suppressions']);
        _hsq.push(['trackPageView']);
    }, []);

    const handleRefreshClick = () => {
        setRefreshKey(refreshKey + 1);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 164 },
        {
            field: 'createdAt', headerName: 'Date', width: 100, valueGetter: (params: GridValueGetterParams) => {
                // return the first 10 characters of the date string
                if (params.value && typeof params.value === 'string') {
                    return params.value.substring(0, 10);
                } else {
                 return params.value
                }
            },
        },
        { field: 'uploadedFilename', headerName: 'Filename', width: 160 },
        { field: 'recordCount', headerName: 'Records', width: 80 },
        { field: 'status', headerName: 'Status', width: 100 },
        { field: 'listID', headerName: 'List ID', width: 80 },
        { field: 'info', headerName: 'More Info', width: 300 },
        {
            field: 'delete', headerName: 'Delete', width: 80, renderCell: (params: GridRenderCellParams) => {
                return <Button onClick={() => deleteSuppression(params.row.id)} size="small">
                    <DeleteForeverIcon color="primary" />
                </Button>
            },
        },
    ]

    const modalStyle = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: props.storefront.activeColors.cart.cartBackground,
        border: '1px solid #5c5c5e',
        boxShadow: 24,
        p: 1,
    };

    const deleteSuppression = (id: string) => {
        if (!window.confirm("Are you sure you want to delete this suppression list?")) {
            return
        }

        try {
            const apiURL = APIEndpoint(EndpointType.Suppressions) + "/" + id;
            httpDelete(apiURL)
                .then((data) => {
                    const response = data as SuppressionListResponse;

                    if (response.status == 'error') {
                        setWarningMessage("Error creating transaction! " + response.errorMessage)
                    } else {
                        setRefreshKey(refreshKey + 1);
                    }
                })
                .catch((error) => {
                    setWarningMessage(error)
                });
        } catch (error) {
            setWarningMessage("failed to submit suppression list")
        }
    }

    const addViaVaultList = () => {
        if (vaultID == "") {
            setWarningMessage("Please enter a Vault List ID")
            return
        }

        setWarningMessage("")

        const suppressionList: SuppressionList = {
            id: "",
            createdAt: "",
            updatedAt: "",
            referenceID: tsgUser?.id || "",
            listID: parseInt(vaultID),
            s3Uri: "",
            columnMappings: {},
            status: SuppressionListStatus.completed,
            fields: [],
            recordCount: 0,
            info: "",
            uploadedFilename: "Not Applicable",
            minifiedS3Uri: "",
            minifiedErrors: []
        }

        const apiURL =  APIEndpoint(EndpointType.Suppressions) + "?via=true";
        httpPost(apiURL, JSON.stringify(suppressionList))
        .then((data) => {
            const response = data as SuppressionListResponse;
            
            if (response.status == 'error') {
                setWarningMessage("Error saving list: " + response.errorMessage)
            } else {
                setRefreshKey(refreshKey + 1);
            }
        })
        .catch((error) => { 
            setWarningMessage(`List was not saved - error: ${error as string}`)
        });    
    }

    const handleSuppressionUploadClose = () => {
        setOpenSuppressionUpload(false);
        setRefreshKey(refreshKey + 1);
    }

      const vaultIDChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setVaultID(event.target.value)
      }

    return (
        <Container maxWidth="xl" disableGutters={true} >
            <Grid item xs={12}>
                <Paper sx={{ p: 1, display: "flex", flexDirection: "column" }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <Title>Suppressions</Title>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                        }}>
                            <IconButton size="medium" type="button" onClick={() => handleRefreshClick()} sx={{ height: 40, marginTop: 1, mr: 1 }} >
                                <RefreshIcon color="primary" />
                            </IconButton>
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                onClick={() => setOpenSuppressionUpload(true)}
                                sx={{}}
                                startIcon={<CloudUploadIcon />}
                            >
                                Upload file
                            </Button>
                        </Box>
                    </Stack>

                    {props.isTSGUser &&
                        <Paper sx={{ mt:2, p:2}}>
                            <Typography variant="h6" sx={{ ml: 1, mt: 1 }}>Add VIA Lists</Typography>
                            <Typography variant="body2" sx={{ ml: 1, mt: 1 }}>Add a list to suppress by entering the VIA Vault List ID.</Typography>
                            <FormControl sx={{ mt: 1 }}>
                                <Tooltip title="Specify only one Vault List ID at a time" arrow>
                                    <TextField sx={{ width: 400 }} id="outlined-basic" label="Vault ID" variant="outlined" size="small" onChange={vaultIDChanged} value={vaultID} />
                                </Tooltip>
                            </FormControl>
                            <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                                <Button variant="contained" color="primary" sx={{ width: 150, marginTop: "8px" }} onClick={addViaVaultList}>Add List</Button>
                            </Stack>
                        </Paper>
                    }

                    {warningMessage != "" && <Alert variant="standard" severity="error" sx={{ ml: 1, mr: 1, mt:2 }}>{warningMessage}</Alert>}
                    <Grid item xs={12} sx={{ mt: 2 }}  >
                        {suppressionsLoading
                            ? <LoadingSpinner title="Loading Suppressions..." textColor="#0352fc" />
                            : suppressionsError
                                ? <ErrorFallBack message={suppressionsError} />
                                :
                                <DataGridPremium autoHeight
                                    rows={suppressions}
                                    columns={columns}
                                    initialState={{
                                        sorting: {
                                            sortModel: [{ field: 'createdAt', sort: 'desc' }],
                                        },
                                    }}
                                    slots={{
                                        noRowsOverlay: NoData,
                                    }}
                                />
                        }
                    </Grid>
                </Paper>
            </Grid>
            <Modal
                open={openSuppressionUpload}
                onClose={handleSuppressionUploadClose}
            >
                <Box sx={modalStyle}>
                    <SuppressionUpload2 storefront={props.storefront} referenceID={tsgUser?.id || ""} close={handleSuppressionUploadClose} />
                </Box>
            </Modal>
        </Container>
    );
};

export default Suppressions;