/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import LoadingSpinner from "../components/LoadingSpinner";

export interface IAuthContextType {
  user: any;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  unverifiedAccount: { email: string; password: string };
  signIn: (p: { email: string; password: string }) => Promise<any>;
  signOut: () => Promise<any>;
  signUp: (p: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phone: string;
    storename: string
  }) => Promise<any>;
  confirmAccount: (p: { code: string }) => Promise<any>;
  forgotPassword: (p: {username: string }) => Promise<any>;
  forgotPasswordSubmit: (p: {username: string, code: string, password: string }) => Promise<any>;
  changePassword: (p: {oldPassword: string, newPassword: string }) => Promise<any>;
  resendConfirmationCode: (p: {username: string }) => Promise<any>;
}


// Create a context object
export const AuthContext = React.createContext<IAuthContextType>({
  user: null,
  isAuthenticated: false,
  isAuthenticating: true,
  unverifiedAccount: {
    email: "",
    password: "",
  },
  signIn: async () => {},
  signOut: async () => {},
  signUp: async () => {},
  confirmAccount: async () => {},
  forgotPassword: async () => {},
  forgotPasswordSubmit: async () => {},
  changePassword: async () => {},
  resendConfirmationCode: async () => {},
});

interface IAuthProviderProps {
  children: React.ReactNode;
}

// Create a provider for components to consume and subscribe to changes
export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [user, setUser] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [unverifiedAccount, setUnverifiedAccount] = useState({
    email: "",
    password: "",
  });

  /**
   * fetch currently logged-in user using AWS Auth library
   * @returns {Promise<void>}
   */
  const fetchAuthUser = async () => {
    try {
      const fetchedUser = await Auth.currentAuthenticatedUser();
      setIsAuthenticating(false);
      setUser(fetchedUser);
      console.log("Fetched User: ", fetchedUser);
    } catch (err) {
      setIsAuthenticating(false);
      setUser(null);
    }
  };

  useEffect(() => {
    fetchAuthUser();
    if (user) {
      Auth.currentSession()
    }

    // listening for auth change events
    const authListener = Hub.listen(
      "auth",
      async ({ payload: { event, data } }) => {
        console.log("Auth Status Changed Event: ", event);
        console.log("Auth Status Changed Data: ", data);

        switch (event) {
          case "confirmSignUp":
            window.location.href = `${window.location.origin}/signin`;
            break;
          case "signIn":
            await fetchAuthUser();
            Auth.currentSession()
            break;
          case "signOut":
            setUser(null);
            window.location.href = `${window.location.origin}/signin`;
            break;
          case "signIn_failure":
          case "signUp_failure":
            if (user) {
              setUser(null);
            }
            break;
          case "signUp":
          case "forgotPassword":
          case "forgotPasswordSubmit":
          case "forgotPasswordSubmit_failure":
            break;
          case "tokenRefresh_failure":
            window.location.href = `${window.location.origin}/signin`;
            break;
          case "forgotPassword_failure":
            break;
          default:
            await fetchAuthUser();
            if (user) {
              Auth.currentSession()
            }
        }
      }
    );

    // cleanup
    return () => {
      authListener();
    };
  }, []);

  /**
   * log user in
   * @param email
   * @param password
   */
  const signIn = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    await Auth.signIn({ username: email, password });
  };

  /**
   * create new user account
   * @param email
   * @param password
   * @param firstName
   * @param lastName
   * @param phone
   * @param storename
   */
  const signUp = async ({
    email,
    password,
    firstName,
    lastName,
    phone,
    storename
  }: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    phone: string;
    storename: string
  }) => {
    await Auth.signUp({
      username: email.toLowerCase(),
      password,
      attributes: {
        email: email.toLocaleLowerCase(),
        name: `${firstName} ${lastName}`,
        phone_number: formatPhoneNumber(phone),
        'custom:storename': storename
      },
    });
    setUnverifiedAccount({ email, password });
  };

  function formatPhoneNumber(input: string): string {
    // Remove non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, '');
  
    // Check if the resulting string is a valid phone number
    const isValidPhoneNumber = /^\d{10}$/.test(digitsOnly);
  
    if (isValidPhoneNumber) {
      // Format the phone number with "+1"
      const formattedPhoneNumber = `+1${digitsOnly}`;
      return formattedPhoneNumber;
    } else {
      // Invalid phone number
      return input;
    }
  }

  /**
   * confirm account using code
   * @param confirmCode
   * @returns {Promise<any>}
   */
  const confirmAccount = async ({ code }: { code: string }) => {
    await Auth.confirmSignUp(unverifiedAccount?.email, code);
    await signIn({
      email: unverifiedAccount?.email,
      password: unverifiedAccount?.password,
    });
  };

  /**
   * logout user
   */
  const signOut = async () => Auth.signOut();

  /**
   * initiates a forgot password request
   * @param username
   * @returns {Promise<any>}
   */
  const forgotPassword = async ({username}: {username: string }) => {
    await Auth.forgotPassword(username);
  }

  /**
   * initiates a forgot password request
   * @param username
   * @param code
   * @param password
   * @returns {Promise<any>}
   */
  const forgotPasswordSubmit = async ({username, code, password}: {username: string, code: string, password: string }) => {
    await Auth.forgotPasswordSubmit(username, code, password)
  }

  /**
   * initiates a change password request
   * @param oldPassword
   * @param newPassword
   * @returns {Promise<any>}
   */
  const changePassword = async ({oldPassword, newPassword}: {oldPassword: string, newPassword: string }) => {
    await Auth.changePassword(user, oldPassword, newPassword);
  }

  /**
   * resends the confirmation code
   * @param username
   * @returns {Promise<any>}
   */
  const resendConfirmationCode = async ({username}: {username: string }) => {
    await Auth.resendSignUp(username);
    setUnverifiedAccount({ email: username, password: "" });
  }

  const value = {
    user,
    isAuthenticated: !!user,
    isAuthenticating,
    unverifiedAccount,
    signIn,
    signOut,
    signUp,
    confirmAccount,
    forgotPassword,
    forgotPasswordSubmit,
    changePassword,
    resendConfirmationCode,
  };

  if (isAuthenticating) {
    return <LoadingSpinner title="Authorizing..." textColor="#0352fc" />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
