import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { CitiesResponse, City } from '../models/City';

export const useQueryCity = (cityStateID: number) => {
    const [city, setcity] = useState<City>({} as City)
    const [cityError, setcityError] = useState("")
    const [cityLoading, setcityLoading] = useState(false)
    
    useEffect(() => {
        if (cityStateID == 0 || isNaN(cityStateID)) {
            return;
        }

        const apiURL =  APIEndpoint(EndpointType.Query) + "/cities/" + cityStateID;

        setcityLoading(true)
        setcityError("")
        setcity({} as City)
        httpGet(apiURL)
            .then((data) => {
                const response = data as CitiesResponse;
                
                if (response.status == "error") {
                    setcityError(response.errorMessage)
                } else {
                    if (response.cities && response.cities.length > 0) {
                        setcity(response.cities[0]);
                    }
                }
            })
            .catch((error) => setcityError(error))
            .finally(() => setcityLoading(false))

    }, [cityStateID]);

    return { city, cityLoading, cityError };
};
