import { FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Stack, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { QueryDataSource, QueryItem } from '../models/DataSource';
import { Storefront } from '../models/StorefrontResponse';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { QueryFilter } from '../models/Blueprint';
import QueryFieldCustom from './QueryFieldCustom';

interface QueryFieldProps {
    dataSource: QueryDataSource | undefined;
    queryItem: QueryItem;
    whitelistedQueryFields: string[];
    storefront: Storefront
    removeField: (fieldID: string) => void;
    updateFilter:(filter: QueryFilter, queryItemID: string) => void;
}

const QueryFieldForm = (props: QueryFieldProps) => {
    const [fieldName, setFieldName] = useState("");
    const [fieldValue, setFieldValue] = useState<string[]>([]); 
    const [fieldValueStartRange, setFieldValueStartRange] = useState<string>("");
    const [fieldValueEndRange, setFieldValueEndRange] = useState<string>("");
    const [fieldValueMultiple, setFieldValueMultiple] = useState<string>(""); // For fields that don't have a values object
    const [fieldCondition, setFieldCondition] = useState("and");
    const [fieldOmit, setFieldOmit] = useState("false");

    useEffect(() => {
        setFieldName(props.queryItem.field.key);
        setFieldValue(props.queryItem.filter.values || []);
        setFieldCondition(props.queryItem.filter.condition || "and");
        setFieldOmit(props.queryItem.filter.omit?.toString() || "false");

        if (isRangeField(props.queryItem.field.key)) {
            if (props.queryItem.filter.values.length === 1) {
                const rangeParts = props.queryItem.filter.values[0].split("-");

                if (rangeParts.length !== 2) {
                    return;
                }
                setFieldValueStartRange(rangeParts[0]);
                setFieldValueEndRange(rangeParts[1]);
            } else if (props.queryItem.filter.values.length === 2) {
                setFieldValueStartRange(props.queryItem.filter.values[0]);
                setFieldValueEndRange(props.queryItem.filter.values[1]);
            }
        } else {
            setFieldValueMultiple(props.queryItem.filter.values.join(","));
        }

    }, [])

    useEffect(() => {
        const filter: QueryFilter = {
            field: fieldName,
            values: createFilterValues(),
            condition: fieldCondition,
            omit: fieldOmit === "true"
        }

        // If the value contains a % wildcard, we need to set the option to 'like'
        if (filter.values.some(v => v.includes("%"))) {
            filter.option = "like";
        }

        props.updateFilter(filter, props.queryItem.id)
    }, [fieldName, fieldValue, fieldCondition, fieldOmit, fieldValueStartRange, fieldValueEndRange, fieldValueMultiple])

    const handleFieldNameChange = (event: SelectChangeEvent) => {
        setFieldName(event.target.value);
    }

    const handleFieldValueChange = (event: SelectChangeEvent<typeof fieldValue>) => {
        const {
            target: { value },
          } = event;
          setFieldValue(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
          );
    }

    const handleFieldConditionChange = (event: SelectChangeEvent) => {
        setFieldCondition(event.target.value);
    }

    const handleFieldOmitChange = (event: SelectChangeEvent) => {
        setFieldOmit(event.target.value);
    }

    const rangeStartValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Remove any punctuation from the value
        const cleanValue = event.target.value.replace(/[,/#!$%^&*;:{}=_`~()]/g, '');

        setFieldValueStartRange(cleanValue);
    }

    const rangeEndValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Remove any punctuation from the value
        const cleanValue = event.target.value.replace(/[,/#!$%^&*;:{}=_`~()]/g, '');

        setFieldValueEndRange(cleanValue);
    }

    const multipleValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValueMultiple(event.target.value);
    }

    const handleCustomFieldChange = (value: string) => {
        console.log("Custom Field Value: ", value)
        setFieldValueMultiple(value);
    }

    const isCustomField = (field: string): boolean => {
        switch (field) {
            case "secondary_state_countycode":
            case "state_countycode":
            case "countycode":
            case "state":
            case "secondary_state":
            case "city_state":
            case "secondary_city_state":
            case "scf":
                return true;
        }
        return false;
    }

    const isRangeField = (field: string): boolean => {
        if (!props.dataSource) {
            return false;
        }

        const fieldObj = props.dataSource.fields?.find(f => f.key === field);

        if (!fieldObj) {
            return false;
        }

        return fieldObj?.range || false;
    }

    const getValues = (field: string): { [key: string]: string } => {
        if (!props.dataSource) {
            return {};
        }

        const fieldObj = props.dataSource.fields?.find(f => f.key === field);

        if (!fieldObj) {
            return {};
        }

        return fieldObj?.values;
    }

    const createFilterValues = (): string[] => {
        if (isRangeField(fieldName)) {
            if (!isNaN(Number(fieldValueStartRange)) && !isNaN(Number(fieldValueEndRange))) {
                return [`${fieldValueStartRange}-${fieldValueEndRange}`];
            } else {
                return [fieldValueStartRange,fieldValueEndRange];
            }
        } else if (Object.entries(getValues(fieldName)).length == 0) {
            const items = typeof fieldValueMultiple === 'string' ? fieldValueMultiple.split(",").map(function(item) {
                return item.trim();
            }) : fieldValueMultiple;
            return items;
        } else {
            return fieldValue;
        }
    }

    const getTooltip = (field: string, moreInfo: string): string => {
        let tip = "";

        switch (field) {
            case "zip4_radius":
                tip = "Expected format: Zip5+Zip4-Radius. Radius is in miles and must be > 0.1. Example: 12345+6789-3";
                break
            case "zip_radius":
                tip = "Expected format: Zip-Radius. Radius is in miles and must be > 0.1. Example: 12345-3";
                break
            case "sale_date":
            case "home_purchase_date":
            case "most_recent_mortgage_date":
            case "move_date":
                tip = "Expected format: YYYY-MM-DD. Example: 2021-12-31";
                break
            case "date_of_name_mmyyyy":
                tip = "Expected format: MMYYYY. Example: 062024";
                break
            case "credit_rating":
                tip = "Credit score compiled and averaged over a Zip+4 region";
                break
        }

        if (moreInfo != "") {
            tip = moreInfo + ". " + tip;
        }

        return tip;
    }

    const friendlyFieldname = (fieldKey: string): string => {
        switch (fieldKey) {
            case "first":
                return "First Name"
            case "middle":
                return "Middle Name"
            case "last":
                return "Last Name"
            case "address":
                if (props.dataSource?.id == 31) {
                    return "Owner Address"
                } else {
                    return "Property Address"
                }
            case "city":
                if (props.dataSource?.id == 31) {
                    return "Owner City"
                } else {
                    return "Property City"
                }
            case "state":
                if (props.dataSource?.id == 31) {
                    return "Geography: Owner State"
                } else {
                    return "Geography: Property State"
                }
            case "zip":
                if (props.dataSource?.id == 31) {
                    return "Geography: Owner Zip"
                } else {
                    return "Geography: Property Zip"
                }
            case "zip4":
                if (props.dataSource?.id == 31) {
                    return "Geography: Owner Zip4"
                } else {
                    return "Geography: Property Zip4"
                }
            case "phone":
                return "Phone"
            case "phone_type":
                return "Phone Type"
            case "dnc":
                return "Do Not Call"
            case "phone_2":
                return "Phone 2"
            case "phone_type_2":
                return "Phone 2 Type"
            case "absentee_owner_computed":
                return "Absentee Owner"
            case "dnc_2":
                return "Do Not Call 2"
            case "birth_month":
                return "Birth Month"
            case "birth_year":
                return "Birth Year"
            case "email":
                return "Email"
            case "latitude":
                return "Latitude"
            case "longitude":
                return "Longitude"
            case "homeowner":
                return "Owner/Renter"
            case "loan_to_value":
                return "Loan to Value"
            case "home_purchase_date":
                return "Home Purchase Date"
            case "lor":
                return "Length of Residence"
            case "property_type":
                return "Property Type"
            case "dwelling":
                return "Dwelling"
            case "home_value":
                return "Home Value (Assessed)"
            case "year_built":
                return "Year Built"
            case "most_recent_mortgage_date":
                return "Most Recent Mortgage Date"
            case "most_recent_mortgage_amount":
                return "Most Recent Mortgage Amount"
            case "exact_age":
                return "Exact Age"
            case "est_age":
                return "Estimated Age"
            case "est_income":
                return "Estimated Income"
            case "marital_status":
                return "Marital Status"
            case "presence_of_children":
                return "Presence of Children"
            case "education":
                return "Education"
            case "address_type":
                return "Address Type"
            case "credit_rating":
                return "Summarized Credit Score"
            case "ethnic_code":
                return "Ethnic Code"
            case "ethnic_group":
                return "Ethnic Group"
            case "gender":
                return "Gender"
            case "investment_properties_owned":
                return "Investment Properties Owned"
            case "language":
                return "Language"
            case "msa_code":
                return "Geography: MSA Code"
            case "net_worth":
                return "Net Worth"
            case "number_of_lines_of_credit":
                return "Number of Lines of Credit"
            case "most_recent_mortgage_interest_rate_type":
                return "Most Recent Mortgage Interest Rate Type"
            case "most_recent_mortgage_loan_type_code":
                return "Most Recent Mortgage Loan Type Code"
            case "pool":
                return "Pool"
            case "trust":
                return "Trust"
            case "mobile_home_ind":
                return "Mobile Home Indicator"
            case "purchase_price":
                return "Purchase Price"
            case "state_countycode":
                return "Geography: State/County Code"
            case "zip4_radius":
                return "Geography: Zip4 Radius"
            case "zip_radius":
                return "Geography: Zip Radius"
            case "property_zip":
                return "Property Zip"
            case "pzip":
                return "Property Zip"
            case "c_level":
                return "C-Level"
            case "city_state":
                return "Geography: City/State Code"
            case "location_type":
                return "Location Type"
            case "public":
                return "Public"
            case "sic_code":
                return "SIC Code"
            case "acres":
                return "Acres"
            case "company":
                return "Company"
            case "land_use":
                return "Land Use"
            case "property_indicator":
                return "Property Indicator"
            case "secondary_city_state":
                return "Property City/State Code"
            case "secondary_zip":
                return "Property Zip"
            case "secondary_state":
                return "Property State"
            case "secondary_state_countycode":
                return "Geography: Property State/County Code"
            case "secondary_zip_radius":
                return "Property Zip Radius"
            case "appr_total_value":
                return "Appraised Total Value"
            case "assd_total_value":
                return "Assessed Total Value"
            case "bedrooms":
                return "Total Bedrooms"
            case "gender_code":
                return "Gender"
            case "income_estimated_household":
                return "Income Estimated Household"
            case "most_recent_mortgage_interest_rate":
                return "Most Recent Mortgage Interest Rate"
            case "living_square_feet":
                return "Living Square Feet"
            case "mkt_total_value":
                return "Market Total Value"
            case "owner_occupied":
                return "Owner Occupied"
            case "sale_date":
                return "Sale Date"
            case "sale_price":
                return "Sale Price"
            case "stories_number":
                return "Total Stories"
            case "total_baths":
                return "Total Baths"
            case "total_value_calculated":
                return "Total Value Calculated"
            case "resort":
                return "Resort"
            case "contact_name_present":
                return "Contact Name Present"
            case "employee_count":
                return "Employee Count"
            case "sale_code":
                return "Sale Code"
            case "title_group":
                return "Title Group"
            case "year_start":
                return "Year Start"
            case "title":
                return "Title"
            case "estimated_home_value":
                return "Estimated Home Value"
            case "home_value_assessed":
                return "Home Value (Assessed)"
            case "home_market_value":
                return "Home Value (Estimated)"
            case "countycode":
                return "Geography: County Code"
            case "dwelling_type":
                return "Dwelling Type"
            case "move_distance_code":
                return "Move Distance"
            case "moved_out_of_state":
                return "Moved Out of State"
            case "moved_out_of_zip":
                return "Moved Out of Zip"
            case "date_of_name_mmyyyy":
                return "Date of Move"
        }

        // Lookup the field name in the props dataSource and then return the name field
        if (props.dataSource && props.dataSource.fields) {
            const field = props.dataSource.fields.find(item => item.key === fieldKey);
            if (field?.name && field.name != "") {
                return field.name
            }
        }

        return fieldKey
    }

    return (
        <Grid sx={{backgroundColor: props.storefront?.activeColors.cart.cartBackground, ml:-2, mt:-2}} component={Paper}>
            <Stack direction="row" spacing={2} sx={{padding:1}}>
                <FormControl fullWidth size="small" sx={{width:300}}>
                    <InputLabel id="trans-type-label">Field Name</InputLabel>
                    <Select labelId="transType" label="Field Name" onChange={handleFieldNameChange} size="small" value={fieldName} MenuProps={{
                                                sx: {
                                                  "&& .Mui-selected": {
                                                    backgroundColor: props.storefront.activeColors.leftMenu.icon
                                                  }
                                                }
                                              }}>
                        {props.whitelistedQueryFields.map((field) => (
                            <MenuItem value={field} key={field}>{friendlyFieldname(field)}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                { isCustomField(fieldName)
                    ? <QueryFieldCustom queryItem={props.queryItem} storefront={props.storefront} updateValue={handleCustomFieldChange} />
                    : isRangeField(fieldName) 
                    ? <Stack direction={"row"} spacing={1} alignItems="center">
                        <Tooltip title={getTooltip(fieldName,"")} placement="top">
                            <TextField fullWidth size="small" sx={{width:140}} label="Range Start" variant="outlined" value={fieldValueStartRange} onChange={rangeStartValueChanged} />
                        </Tooltip>
                        <Typography sx={{}}>-</Typography>
                        <Tooltip title={getTooltip(fieldName,"")} placement="top">
                            <TextField fullWidth size="small" sx={{width:140}} label="Range End" variant="outlined" value={fieldValueEndRange} onChange={rangeEndValueChanged} />
                        </Tooltip>
                      </Stack>
                    : Object.entries(getValues(fieldName)).length == 0
                    ? <Tooltip title={getTooltip(fieldName,"Separate multiple values with commas")} placement="top">
                        <TextField fullWidth size="small" sx={{width:300}} label="Values" variant="outlined" value={fieldValueMultiple} onChange={multipleValueChanged} /></Tooltip>
                    : <FormControl fullWidth size="small" sx={{width:300}}>
                    <InputLabel id="values-label">Values</InputLabel>
                        <Select labelId="values" multiple label="Values" onChange={handleFieldValueChange} size="small" value={fieldValue} MenuProps={{
                                                    sx: {
                                                    "&& .Mui-selected": {
                                                        backgroundColor: props.storefront.activeColors.leftMenu.icon
                                                    }
                                                    }
                                                }}>
                            {Object.entries(getValues(fieldName)).map(([key, value]) => (
                                <MenuItem value={key} key={key}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }

                <Tooltip title="To group items together, specify the same Group number" placement="top">
                    <FormControl fullWidth size="small" sx={{width:120}}>
                        <InputLabel id="condition-label">Condition</InputLabel>
                        <Select labelId="condition" label="Condition" onChange={handleFieldConditionChange} size="small" value={fieldCondition}MenuProps={{
                                                    sx: {
                                                    "&& .Mui-selected": {
                                                        backgroundColor: props.storefront.activeColors.leftMenu.icon
                                                    }
                                                    }
                                                }}>
                            <MenuItem value="and" key="and">And</MenuItem>
                            <MenuItem value="0" key="0">Group #1</MenuItem>
                            <MenuItem value="1" key="1">Group #2</MenuItem>
                            <MenuItem value="2" key="2">Group #3</MenuItem>
                        </Select>
                    </FormControl>
                </Tooltip>
                <Tooltip title="Select 'true' to remove records matching this field" placement="top">
                    <FormControl fullWidth size="small" sx={{width:100}}>
                        <InputLabel id="trans-type-label">Omit</InputLabel>
                        <Select labelId="transType" label="Omit" onChange={handleFieldOmitChange} size="small" value={fieldOmit}MenuProps={{
                                                    sx: {
                                                    "&& .Mui-selected": {
                                                        backgroundColor: props.storefront.activeColors.leftMenu.icon
                                                    }
                                                    }
                                                }}>
                            <MenuItem value="true" key="true">True</MenuItem>
                            <MenuItem value="false" key="false">False</MenuItem>
                        </Select>
                    </FormControl>
                </Tooltip>
                <IconButton sx={{marginTop:-.5, color:props.storefront.activeColors.cart.cartIconBackground}} size="small" type="button" onClick={() => props.removeField(props.queryItem.id) } >
                    <DeleteForeverIcon />
                </IconButton>
            </Stack>
        </Grid>
    );
};

export default QueryFieldForm;