import { Box, CircularProgress, Container, FormControl, Grid, IconButton, InputLabel, Link, MenuItem, Modal, Paper, Select, SelectChangeEvent, Snackbar, Stack, Tooltip, Typography } from "@mui/material"
import Title from "../components/Title"
import { Storefront } from "../models/StorefrontResponse"
import React, { useEffect, useState } from "react"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import { useAPILogCounts } from "../hooks/useAPILogCounts"
import { BarChart, BarChartProps } from "@mui/x-charts/BarChart"
import CloseIcon from "@mui/icons-material/Close";
import { BarItemIdentifier } from "@mui/x-charts/models"
import { PeriodTimeRange, QueryAPILog } from "../models/APILogs"
import { useAPILogs } from "../hooks/useAPILogs"
import { GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid"
import { DataGridPremium } from "@mui/x-data-grid-premium"
import { styled } from '@mui/material/styles';
import APICallDetails from "../components/APICallDetails"
import RefreshIcon from '@mui/icons-material/Refresh';
import { LocalTime } from "../utils/localTime"
import { useCompanies } from "../hooks/useCompanies"
import { useTSGUser } from "../contexts/TSGUserContext"

interface Props {
    storefront: Storefront,
}

const chartSetting: Partial<BarChartProps> = {
    height: 300,
};

const APILogs = (props: Props) => {
    const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(dayjs(getDefaultStartDate()));
    const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(dayjs(new Date()));
    const [detailsTimeRange, setDetailsTimeRange] = useState<PeriodTimeRange | null>(null);
    const [periodSize, setPeriodSize] = useState<string>("day");
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMsg, setSnackbarMsg] = useState("")
    const [openCallDetails, setOpenCallDetails] = useState(false);
    const [companyRoles] = useState<string[]>(["query_api"])
    const [companyID, setCompanyID] = useState<string>(props.storefront.companyID)
    const [callDetailsLog, setCallDetailsLog] = useState<QueryAPILog>({ apiKey: "", id: "", companyID: "", queryCustomer: "", createdAt: "", endpoint: "", apiDuration: 0, clientInfo: { userID: "", ipAddress: "" }, requestID: "", billingInfo: { listID: "", listCountQuantity: 0, listOrderQuantity: 0, lookupQuantity: 0 } })
    const [countsRefresh, setCountsRefresh] = useState(0)
    const { logCounts, logCountsLoading, logCountsError, periodLabels, timeRanges } = useAPILogCounts(companyID, startDate?.format("YYYY-MM-DDT") + "00:00:00.000Z" || "", endDate?.format("YYYY-MM-DDT") + "23:59:59.999Z" || "", periodSize, countsRefresh);
    const { logs, logsLoading, logsError } = useAPILogs(companyID, detailsTimeRange || { start: "", end: "" });
    const { companies, companiesError } = useCompanies(companyRoles)
    const { tsgUser } = useTSGUser();

    useEffect(() => {
        if (logCountsError !== "") {
            setSnackbarMsg("Error retrieving log counts - " + logCountsError)
            setOpenSnackbar(true)
        } else if (logsError !== "") {
            setSnackbarMsg("Error retrieving logs - " + logsError)
            setOpenSnackbar(true)
        }
    }, [logCountsError, logsError])

    useEffect(() => {
        if (companiesError !== "") {
            setSnackbarMsg("Error retrieving companies - " + companiesError)
            setOpenSnackbar(true)
        }
    }, [companiesError])

    useEffect(() => {
        console.log("Loaded", companies?.length, "companies")
    }, [companies])

    useEffect(() => {
        console.log("APILogs loaded...")
    }, [])

    const isTSGUser = (): boolean => {
        return tsgUser != undefined && tsgUser.email != undefined && (tsgUser.email).includes("@theshare.group")
    }

    const columns = [
        {
            field: 'createdAt', headerName: 'Time (UTC)', width: 200, renderCell: (params: GridRenderCellParams) => {
                return <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        setCallDetailsLog(params.row);
                        setOpenCallDetails(true);
                    }}
                >
                    <Tooltip title={LocalTime(params.value)} placement="bottom-start">
                        <Typography variant="body2">{params.value}</Typography>
                    </Tooltip>
                </Link>
            }
        },
        { field: 'endpoint', headerName: 'Endpoint', width: 200 },
        { field: 'apiDuration', headerName: 'Duration', width: 90 },
        {
            field: 'clientInfo', headerName: 'IP Address', width: 140, valueGetter: (params: GridValueGetterParams) => {
                return params.row.clientInfo.ipAddress
            },
        },
        {
            field: 'billingInfo', headerName: 'Call Summary', width: 300, valueGetter: (params: GridValueGetterParams) => {
                return assembleLogSummary(params.row)
            },
        },
    ]

    function getDefaultStartDate() {
        const today = new Date();
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 7);
        return sevenDaysAgo;
    }

    const handlePeriodSizeChange = (event: SelectChangeEvent<typeof periodSize>) => {
        setPeriodSize(event.target.value as string);
    };

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const handleRefreshClick = () => {
        setCountsRefresh(countsRefresh + 1)
    }

    const assembleLogSummary = (log: QueryAPILog): string => {
        switch (log.endpoint) {
            case "graphql/lg_counts":
            case "graphql/lg_blueprintCounts":
                if (log.billingInfo.listCountQuantity) {
                    return "List Count: " + log.billingInfo.listCountQuantity + " leads"
                } else {
                    return "List Count: Unknown"
                }
            case "graphql/lg_createList":
            case "graphql/lg_createCustomList":
                if (log.billingInfo.listOrderQuantity) {
                    return "List Order: " + log.billingInfo.listOrderQuantity + " leads (" + log.billingInfo.listID + ")"
                } else {
                    return "List Order: Unknown"
                } case "graphql/lg_dataSource":
                if (log.queryParams) {
                    const params = JSON.stringify(log.queryParams)
                    return "Data Source: " + params.match(/"id":\s*"(.*?)"/)?.[1] || "Unknown"
                } else {
                    return "Data Source: Unknown"
                }
            case "graphql/lg_list":
                if (log.billingInfo.listCountQuantity && log.billingInfo.listID) {
                    return "List Contents: " + log.billingInfo.listCountQuantity + " leads (" + log.billingInfo.listID + ")"
                } else {
                    return "Results: Unknown"
                }
            case "graphql/lg_lookupAddress":
            case "graphql/lg_lookupEmail":
            case "graphql/lg_lookupPhone":
            case "graphql/lg_lookupName":
            case "query/lg_lookupPhone":
            case "query/lg_lookupName":
            case "query/lg_lookupAddress":
            case "graphql/orders":
            case "graphql/lg_lookup":
                if (log.billingInfo.lookupQuantity) {
                    return "Record Count: " + log.billingInfo.lookupQuantity
                } else {
                    return "Record Count: 0"
                }
            case "graphql/lg_lookupAddressCount":
            case "graphql/lg_lookupEmailCount":
            case "graphql/lg_lookupPhoneCount":
            case "graphql/lg_lookupNameCount":
            case "graphql/lg_lookupCount":
                if (log.billingInfo.listCountQuantity) {
                    return "Record Count: " + log.billingInfo.listCountQuantity
                } else {
                    return "Record Count: 0"
                }
            case "query/ds_phone":
            case "query/ds_name":
            case "query/ds_address":
            case "query/ds_none":
                if (log.billingInfo.listCountQuantity) {
                    return "Record Count: " + log.billingInfo.listCountQuantity
                } else {
                    return "Record Count: 0"
                }
        }
        return "N/A"
    }

    const orderDetailsStyle = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: props.storefront.activeColors.addProductForm.formBackground,
        border: '1px solid #5c5c5e',
        boxShadow: 24,
        p: 1,
    };

    const handleCallDetailsClose = () => setOpenCallDetails(false);

    const snackbarAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const onBarChartItemClick = (event: React.MouseEvent, barItemIdentifier: BarItemIdentifier) => {
        setDetailsTimeRange(timeRanges[barItemIdentifier.dataIndex])
    }

    const StyledGridOverlay = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& .ant-empty-img-1': {
            fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
            fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
    }));

    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>
                <Box width={100}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.46667 10.583341125" version="1.1" x="0px" y="0px"><g transform="translate(-98.96158,-92.669207)">
                        <path d="m 102.98242,93.728516 c -0.63741,0 -1.21283,0.05788 -1.64844,0.158203 -0.2178,0.05016 -0.39948,0.108137 -0.54882,0.189453 -0.0747,0.04066 -0.14349,0.08718 -0.20313,0.154297 -0.0596,0.06712 -0.11328,0.165934 -0.11328,0.279297 0,0.113362 0.0537,0.214132 0.11328,0.28125 0.0596,0.06712 0.12846,0.111686 0.20313,0.152343 0.14934,0.08132 0.33102,0.141246 0.54882,0.191407 0.43561,0.100321 1.01103,0.158202 1.64844,0.158203 0.63742,0 1.21478,-0.05788 1.65039,-0.158203 0.21781,-0.05016 0.39949,-0.110091 0.54883,-0.191407 0.0747,-0.04066 0.14154,-0.08522 0.20117,-0.152343 0.0219,-0.02465 0.0232,-0.07103 0.041,-0.103516 a 0.26458299,0.26458299 0 0 0 0.0723,-0.177734 0.26458299,0.26458299 0 0 0 -0.0664,-0.164063 c -0.0191,-0.03746 -0.0223,-0.08753 -0.0469,-0.115234 -0.0596,-0.06712 -0.1265,-0.113639 -0.20117,-0.154297 -0.14934,-0.08132 -0.33102,-0.139293 -0.54883,-0.189453 -0.43561,-0.100322 -1.01297,-0.158204 -1.65039,-0.158203 z m 0,0.527343 c 0.60449,0 1.153,0.05937 1.53125,0.146485 0.14713,0.03388 0.23856,0.07145 0.32227,0.107422 -0.0837,0.03598 -0.17508,0.07548 -0.32227,0.109375 -0.37825,0.08711 -0.92676,0.144531 -1.53125,0.144531 -0.60448,-10e-7 -1.15105,-0.05742 -1.52929,-0.144531 -0.14657,-0.03375 -0.23859,-0.07352 -0.32227,-0.109375 0.0837,-0.03585 0.17576,-0.07368 0.32227,-0.107422 0.37824,-0.08711 0.92481,-0.146484 1.52929,-0.146485 z" fill="#000000" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="m 100.73438,95.798828 a 0.26458299,0.26458299 0 0 0 -0.26563,0.265625 c 1e-5,0.113361 0.0536,0.212184 0.11328,0.279297 0.0596,0.06711 0.12846,0.113641 0.20313,0.154297 0.14934,0.08131 0.33102,0.139293 0.54882,0.189453 0.43561,0.100319 1.01104,0.158202 1.64844,0.158203 0.63741,10e-7 1.21478,-0.05788 1.65039,-0.158203 0.21781,-0.05016 0.39949,-0.10814 0.54883,-0.189453 0.0747,-0.04066 0.14153,-0.08718 0.20117,-0.154297 0.0596,-0.06711 0.11327,-0.165935 0.11328,-0.279297 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.265625 0.26458299,0.26458299 0 0 0 -0.26562,0.265625 c 0,-0.04186 0.0218,-0.07258 0.0215,-0.07227 -2.7e-4,3.1e-4 -0.0214,0.01972 -0.0606,0.04102 -0.0782,0.0426 -0.22493,0.09512 -0.41406,0.138672 -0.37825,0.08711 -0.92677,0.144532 -1.53125,0.144531 -0.60447,-10e-7 -1.15105,-0.05742 -1.52929,-0.144531 -0.18913,-0.04355 -0.33778,-0.09607 -0.41602,-0.138672 -0.0391,-0.0213 -0.0583,-0.04071 -0.0586,-0.04102 -2.8e-4,-3.09e-4 0.0195,0.03041 0.0195,0.07227 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.265625 z" fill="#000000" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="m 105.23242,94.246094 a 0.26458299,0.26458299 0 0 0 -0.26562,0.263672 v 3.591796 a 0.26458299,0.26458299 0 0 0 0.26562,0.263672 0.26458299,0.26458299 0 0 0 0.26367,-0.263672 v -3.591796 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.263672 z" fill="#000000" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="m 100.73438,94.246094 a 0.26458299,0.26458299 0 0 0 -0.26563,0.263672 v 4.660156 a 0.26458299,0.26458299 0 0 0 0.26563,0.265625 0.26458299,0.26458299 0 0 0 0.26367,-0.265625 v -4.660156 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.263672 z" fill="#000000" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="m 100.73438,97.353516 a 0.26458299,0.26458299 0 0 0 -0.26563,0.263671 c 1e-5,0.113361 0.0536,0.212184 0.11328,0.279297 0.0596,0.06711 0.12846,0.113641 0.20313,0.154297 0.14934,0.08131 0.33102,0.141247 0.54882,0.191406 0.43561,0.10032 1.01104,0.158202 1.64844,0.158204 0.63741,0 1.21478,-0.05788 1.65039,-0.158204 0.21781,-0.05016 0.39949,-0.110093 0.54883,-0.191406 0.0747,-0.04066 0.14153,-0.08718 0.20117,-0.154297 0.0596,-0.06711 0.11327,-0.165935 0.11328,-0.279297 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.263671 0.26458299,0.26458299 0 0 0 -0.26562,0.263671 c 0,-0.04186 0.0218,-0.07258 0.0215,-0.07227 -2.7e-4,3.1e-4 -0.0214,0.01972 -0.0606,0.04102 -0.0782,0.0426 -0.22493,0.09707 -0.41406,0.140625 -0.37825,0.08711 -0.92677,0.144532 -1.53125,0.144532 -0.60447,-2e-6 -1.15105,-0.05742 -1.52929,-0.144532 -0.18913,-0.04355 -0.33778,-0.09803 -0.41602,-0.140625 -0.0391,-0.0213 -0.0583,-0.04071 -0.0586,-0.04102 -2.8e-4,-3.1e-4 0.0195,0.03041 0.0195,0.07227 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.263671 z" fill="#000000" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="m 100.73438,98.90625 a 0.26458299,0.26458299 0 0 0 -0.26563,0.263672 c 0,0.113362 0.0537,0.214132 0.11328,0.28125 0.0596,0.06712 0.12846,0.113639 0.20313,0.154297 0.14934,0.08132 0.33102,0.139292 0.54882,0.189453 0.43561,0.100322 1.01103,0.158203 1.64844,0.158203 0.35063,0 0.69633,-0.01718 1.01367,-0.05273 a 0.26458299,0.26458299 0 0 0 0.23438,-0.292969 0.26458299,0.26458299 0 0 0 -0.29297,-0.234375 c -0.29496,0.03305 -0.62295,0.05078 -0.95508,0.05078 -0.60448,0 -1.15105,-0.05742 -1.52929,-0.144531 -0.18913,-0.04355 -0.33779,-0.09607 -0.41602,-0.138672 -0.0391,-0.0213 -0.0583,-0.04071 -0.0586,-0.04102 a 0.26458299,0.26458299 0 0 0 -0.24414,-0.193359 z" fill="#000000" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="m 104.80664,98.701172 a 0.26458299,0.26458299 0 0 0 -0.18555,0.07617 0.26458299,0.26458299 0 0 0 0,0.375 L 105.46875,100 a 0.26458299,0.26458299 0 0 0 0.37305,0 0.26458299,0.26458299 0 0 0 0,-0.375 l -0.84766,-0.847656 a 0.26458299,0.26458299 0 0 0 -0.1875,-0.07617 z" fill="#000000" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M 105.46875,98.777344 104.62109,99.625 a 0.26458299,0.26458299 0 0 0 0,0.375 0.26458299,0.26458299 0 0 0 0.37305,0 l 0.84766,-0.847656 a 0.26458299,0.26458299 0 0 0 0,-0.375 0.26458299,0.26458299 0 0 0 -0.37305,0 z" fill="#000000" stroke-linecap="round" stroke-linejoin="round" /></g>
                    </svg>
                    <Typography>Click on a bar in the chart to view details</Typography>
                </Box>
                {/* <Box sx={{ mt: 1, color:"#797a7a" }}>No Orders</Box> */}
            </StyledGridOverlay>
        );
    }

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                        <Title>API Call Frequency</Title>
                        <Stack direction="row" justifyContent="right">
                            {logCountsLoading && <div><CircularProgress sx={{ mr: 5 }} color="inherit" /></div>}
                            {isTSGUser() && companies && companies.length > 0 &&
                                <FormControl size="medium" sx={{ width: 230, height: 70, mr: 1 }}>
                                    <InputLabel id="company-list-label">Company</InputLabel>
                                    <Select labelId="company-list" label="Company" onChange={(event) => setCompanyID(event.target.value as string)} size="medium" value={companyID}>
                                        {companies.map((company) => (
                                            <MenuItem value={company.id} key={company.id}>{company.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DatePicker label="Start Date" value={dayjs(startDate)} onChange={date => setStartDate(date)} sx={{ marginBottom: "8px" }} />
                                <DatePicker label="End Date" value={dayjs(endDate)} onChange={date => setEndDate(date)} sx={{ marginBottom: "8px", marginLeft: "8px" }} />
                            </LocalizationProvider>
                            <FormControl size="medium" sx={{ width: 200, height: 70, marginLeft: "8px" }}>
                                <InputLabel id="trans-type-label">Period Size</InputLabel>
                                <Select labelId="periodSize" label="Period Size" onChange={handlePeriodSizeChange} size="medium" value={periodSize}>
                                    <MenuItem value="minute" key="minute">Minute</MenuItem>
                                    <MenuItem value="hour" key="hour">Hour</MenuItem>
                                    <MenuItem value="day" key="day">Day</MenuItem>
                                    <MenuItem value="month" key="month">Month</MenuItem>
                                </Select>
                            </FormControl>
                            <IconButton size="medium" type="button" onClick={() => handleRefreshClick()} sx={{ height: 40, marginTop: 1 }} >
                                <RefreshIcon color="primary" />
                            </IconButton>
                        </Stack>
                        <Box sx={{ flexGrow: 1 }}>
                            {logCounts && logCounts.length > 0
                                ? <BarChart
                                    xAxis={[{ data: periodLabels || [], scaleType: "band" }]}
                                    series={logCounts || []}
                                    {...chartSetting}
                                    yAxis={[{ label: "API Calls", },]}
                                    onItemClick={onBarChartItemClick}
                                    slotProps={{ legend: { hidden: true } }}
                                />
                                : <Stack alignItems="center" justifyContent="center">
                                    <Box width={100}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.46667 10.583341125" version="1.1" x="0px" y="0px"><g transform="translate(-98.96158,-92.669207)">
                                            <path d="m 102.98242,93.728516 c -0.63741,0 -1.21283,0.05788 -1.64844,0.158203 -0.2178,0.05016 -0.39948,0.108137 -0.54882,0.189453 -0.0747,0.04066 -0.14349,0.08718 -0.20313,0.154297 -0.0596,0.06712 -0.11328,0.165934 -0.11328,0.279297 0,0.113362 0.0537,0.214132 0.11328,0.28125 0.0596,0.06712 0.12846,0.111686 0.20313,0.152343 0.14934,0.08132 0.33102,0.141246 0.54882,0.191407 0.43561,0.100321 1.01103,0.158202 1.64844,0.158203 0.63742,0 1.21478,-0.05788 1.65039,-0.158203 0.21781,-0.05016 0.39949,-0.110091 0.54883,-0.191407 0.0747,-0.04066 0.14154,-0.08522 0.20117,-0.152343 0.0219,-0.02465 0.0232,-0.07103 0.041,-0.103516 a 0.26458299,0.26458299 0 0 0 0.0723,-0.177734 0.26458299,0.26458299 0 0 0 -0.0664,-0.164063 c -0.0191,-0.03746 -0.0223,-0.08753 -0.0469,-0.115234 -0.0596,-0.06712 -0.1265,-0.113639 -0.20117,-0.154297 -0.14934,-0.08132 -0.33102,-0.139293 -0.54883,-0.189453 -0.43561,-0.100322 -1.01297,-0.158204 -1.65039,-0.158203 z m 0,0.527343 c 0.60449,0 1.153,0.05937 1.53125,0.146485 0.14713,0.03388 0.23856,0.07145 0.32227,0.107422 -0.0837,0.03598 -0.17508,0.07548 -0.32227,0.109375 -0.37825,0.08711 -0.92676,0.144531 -1.53125,0.144531 -0.60448,-10e-7 -1.15105,-0.05742 -1.52929,-0.144531 -0.14657,-0.03375 -0.23859,-0.07352 -0.32227,-0.109375 0.0837,-0.03585 0.17576,-0.07368 0.32227,-0.107422 0.37824,-0.08711 0.92481,-0.146484 1.52929,-0.146485 z" fill="#c9bebd" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="m 100.73438,95.798828 a 0.26458299,0.26458299 0 0 0 -0.26563,0.265625 c 1e-5,0.113361 0.0536,0.212184 0.11328,0.279297 0.0596,0.06711 0.12846,0.113641 0.20313,0.154297 0.14934,0.08131 0.33102,0.139293 0.54882,0.189453 0.43561,0.100319 1.01104,0.158202 1.64844,0.158203 0.63741,10e-7 1.21478,-0.05788 1.65039,-0.158203 0.21781,-0.05016 0.39949,-0.10814 0.54883,-0.189453 0.0747,-0.04066 0.14153,-0.08718 0.20117,-0.154297 0.0596,-0.06711 0.11327,-0.165935 0.11328,-0.279297 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.265625 0.26458299,0.26458299 0 0 0 -0.26562,0.265625 c 0,-0.04186 0.0218,-0.07258 0.0215,-0.07227 -2.7e-4,3.1e-4 -0.0214,0.01972 -0.0606,0.04102 -0.0782,0.0426 -0.22493,0.09512 -0.41406,0.138672 -0.37825,0.08711 -0.92677,0.144532 -1.53125,0.144531 -0.60447,-10e-7 -1.15105,-0.05742 -1.52929,-0.144531 -0.18913,-0.04355 -0.33778,-0.09607 -0.41602,-0.138672 -0.0391,-0.0213 -0.0583,-0.04071 -0.0586,-0.04102 -2.8e-4,-3.09e-4 0.0195,0.03041 0.0195,0.07227 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.265625 z" fill="#c9bebd" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="m 105.23242,94.246094 a 0.26458299,0.26458299 0 0 0 -0.26562,0.263672 v 3.591796 a 0.26458299,0.26458299 0 0 0 0.26562,0.263672 0.26458299,0.26458299 0 0 0 0.26367,-0.263672 v -3.591796 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.263672 z" fill="#c9bebd" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="m 100.73438,94.246094 a 0.26458299,0.26458299 0 0 0 -0.26563,0.263672 v 4.660156 a 0.26458299,0.26458299 0 0 0 0.26563,0.265625 0.26458299,0.26458299 0 0 0 0.26367,-0.265625 v -4.660156 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.263672 z" fill="#c9bebd" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="m 100.73438,97.353516 a 0.26458299,0.26458299 0 0 0 -0.26563,0.263671 c 1e-5,0.113361 0.0536,0.212184 0.11328,0.279297 0.0596,0.06711 0.12846,0.113641 0.20313,0.154297 0.14934,0.08131 0.33102,0.141247 0.54882,0.191406 0.43561,0.10032 1.01104,0.158202 1.64844,0.158204 0.63741,0 1.21478,-0.05788 1.65039,-0.158204 0.21781,-0.05016 0.39949,-0.110093 0.54883,-0.191406 0.0747,-0.04066 0.14153,-0.08718 0.20117,-0.154297 0.0596,-0.06711 0.11327,-0.165935 0.11328,-0.279297 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.263671 0.26458299,0.26458299 0 0 0 -0.26562,0.263671 c 0,-0.04186 0.0218,-0.07258 0.0215,-0.07227 -2.7e-4,3.1e-4 -0.0214,0.01972 -0.0606,0.04102 -0.0782,0.0426 -0.22493,0.09707 -0.41406,0.140625 -0.37825,0.08711 -0.92677,0.144532 -1.53125,0.144532 -0.60447,-2e-6 -1.15105,-0.05742 -1.52929,-0.144532 -0.18913,-0.04355 -0.33778,-0.09803 -0.41602,-0.140625 -0.0391,-0.0213 -0.0583,-0.04071 -0.0586,-0.04102 -2.8e-4,-3.1e-4 0.0195,0.03041 0.0195,0.07227 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.263671 z" fill="#c9bebd" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="m 100.73438,98.90625 a 0.26458299,0.26458299 0 0 0 -0.26563,0.263672 c 0,0.113362 0.0537,0.214132 0.11328,0.28125 0.0596,0.06712 0.12846,0.113639 0.20313,0.154297 0.14934,0.08132 0.33102,0.139292 0.54882,0.189453 0.43561,0.100322 1.01103,0.158203 1.64844,0.158203 0.35063,0 0.69633,-0.01718 1.01367,-0.05273 a 0.26458299,0.26458299 0 0 0 0.23438,-0.292969 0.26458299,0.26458299 0 0 0 -0.29297,-0.234375 c -0.29496,0.03305 -0.62295,0.05078 -0.95508,0.05078 -0.60448,0 -1.15105,-0.05742 -1.52929,-0.144531 -0.18913,-0.04355 -0.33779,-0.09607 -0.41602,-0.138672 -0.0391,-0.0213 -0.0583,-0.04071 -0.0586,-0.04102 a 0.26458299,0.26458299 0 0 0 -0.24414,-0.193359 z" fill="#c9bebd" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="m 104.80664,98.701172 a 0.26458299,0.26458299 0 0 0 -0.18555,0.07617 0.26458299,0.26458299 0 0 0 0,0.375 L 105.46875,100 a 0.26458299,0.26458299 0 0 0 0.37305,0 0.26458299,0.26458299 0 0 0 0,-0.375 l -0.84766,-0.847656 a 0.26458299,0.26458299 0 0 0 -0.1875,-0.07617 z" fill="#c9bebd" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M 105.46875,98.777344 104.62109,99.625 a 0.26458299,0.26458299 0 0 0 0,0.375 0.26458299,0.26458299 0 0 0 0.37305,0 l 0.84766,-0.847656 a 0.26458299,0.26458299 0 0 0 0,-0.375 0.26458299,0.26458299 0 0 0 -0.37305,0 z" fill="#c9bebd" stroke-linecap="round" stroke-linejoin="round" /></g>
                                        </svg>
                                    </Box>
                                    <Typography sx={{ height: 30, mt: -4 }}>No logs exist for the selected period</Typography>
                                </Stack>
                            }
                        </Box>
                        <Title>API Call Details</Title>
                        {logsLoading
                            ? <div><CircularProgress sx={{ mr: 5 }} color="inherit" /></div>
                            : logsError
                                ? <Typography>{logsError}</Typography>
                                :
                                <DataGridPremium
                                    rows={logs || []}
                                    columns={columns}
                                    initialState={{
                                        sorting: {
                                            sortModel: [{ field: 'orderDate', sort: 'desc' }],
                                        },
                                    }}
                                    slots={{
                                        noRowsOverlay: CustomNoRowsOverlay,
                                    }}
                                />}
                    </Paper>
                </Grid>
            </Grid>
            <Modal
                open={openCallDetails}
                onClose={handleCallDetailsClose}
            >
                <Box sx={orderDetailsStyle}>
                    <APICallDetails apiCallLog={callDetailsLog} close={handleCallDetailsClose} storefront={props.storefront} />
                </Box>
            </Modal>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMsg}
                action={snackbarAction}
            />
        </Container>
    )
}

export default APILogs;