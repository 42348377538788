import { useEffect, useState } from 'react';
import { APIEndpoint, EndpointType, httpGet } from "../utils/apiService";
import axios from 'axios';
import { S3UploadResponseConfig, UploadSignedURLResponse } from '../models/S3FileUpload';

export const useS3FileS3Upload = (file: File, startS3Upload: boolean, customerID: string, suppressionRefID: string) => {
    const [S3UploadProgress, setS3UploadProgress] = useState(0)
    const [S3UploadError, setS3UploadError] = useState("")
    const [S3UploadCompleted, setS3UploadCompleted] = useState(false)
    const [S3UploadResponse, setS3UploadResponse] = useState<S3UploadResponseConfig>()
    const [S3UploadSuppressionID, setS3UploadSuppressionID] = useState<string>("")

    function parseURLMeat(url: string): string {
        const idx = url.indexOf(".amazonaws.com")

        if (idx < 0) {
            return url
        }

        return url.substring(idx + 14)
    }

    useEffect(() => {
        runUpload()
    }, [startS3Upload]);

    // cleanFilename removes any special characters from the filename that will cause issues with S3
    function cleanFilename(filename: string): string {
        return filename.replace(/[^a-zA-Z0-9.]/g, "_");
    }

    function getMimeType(file: File): string {
        const mimeType = file.type
        if (mimeType) {
            return mimeType;
        }

        if (file.name.toLocaleLowerCase().endsWith('.csv')) {
            return 'text/csv';
        }

        if (file.name.toLocaleLowerCase().endsWith('.xls')) {
            return 'application/vnd.ms-excel';
        }

        if (file.name.toLocaleLowerCase().endsWith('.xlsx')) {
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        }

        return 'application/octet-stream';
    }

    async function runUpload() {
        setS3UploadCompleted(false)
        setS3UploadProgress(0)
        setS3UploadError("")
        setS3UploadResponse({} as S3UploadResponseConfig)
        setS3UploadSuppressionID("")

        if (!startS3Upload) {
            return
        }

        try {
            let apiURL = APIEndpoint(EndpointType.Upload) + `/url?user-id=${customerID}&filename=${cleanFilename(file.name)}`

            if (suppressionRefID) {
                apiURL += `&suppression-ref-id=${suppressionRefID}`
            }

            console.log('[S3FILEUPLOAD] ' + parseURLMeat(apiURL));

            httpGet(apiURL)
                .then((data) => {
                    const uploadSignedResponse = data as UploadSignedURLResponse

                    if (uploadSignedResponse.status == "error") {
                        setS3UploadError(uploadSignedResponse.errorMessage)
                        setS3UploadProgress(0)
                        return { S3UploadProgress, S3UploadError, S3UploadCompleted };
                    }

                    console.log("Signed Upload URL: ", uploadSignedResponse.uploadURL)

                    axios.put(uploadSignedResponse.uploadURL, file, {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            if (total != undefined) {
                                const percentage = Math.floor((loaded * 100) / total);
                                setS3UploadProgress(percentage);
                            }
                        },
                        headers: {
                            'Content-Type': getMimeType(file),
                        }
                    }).then((res) => {
                        console.log("S3 Upload Response: ", res)
                        //const s3UploadResponse = res. as S3UploadResponse
                        setS3UploadResponse(res.config as S3UploadResponseConfig)
                        setS3UploadProgress(100);
                        setS3UploadSuppressionID(uploadSignedResponse.suppressionListID ? uploadSignedResponse.suppressionListID : "")
                        setS3UploadCompleted(true);
                    }).catch((error) => {
                        setS3UploadError("failed to upload file - " + error)
                    }
                    ).finally(() => {
                        setS3UploadProgress(100);
                    });
                    

                }).catch((error) => {
                    setS3UploadError("failed to upload file - " + error)
                }).finally(() => {
                });
        } catch (error) {
            setS3UploadError("failed to upload file")
        }
    }

    return { S3UploadResponse, S3UploadProgress, S3UploadError, S3UploadCompleted, S3UploadSuppressionID };
}