import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { VaultListCount, VaultListCountResponse } from '../models/Vault';

export const useQueryVault = (vaultIDs: string[]) => {
    const [vaultCount, setVaultCount] = useState<number>(0)
    const [vaultListCounts, setVaultListCounts] = useState<VaultListCount[]>([])
    const [vaultError, setVaultError] = useState("")
    const [vaultLoading, setVaultLoading] = useState(false)
    
    useEffect(() => {
        if (vaultIDs.length == 0) {
            return;
        }

        setVaultLoading(true)
        setVaultError("")
        setVaultCount(0)
        setVaultListCounts([])

        // Escape the url
        const apiURL =  APIEndpoint(EndpointType.Query) + "/vault?ids=" + vaultIDs.join(",");

        httpGet(apiURL)
            .then((data) => {
                const response = data as VaultListCountResponse;
                
                if (response.status == "error") {
                    setVaultError(response.errorMessage)
                } else {
                    setVaultCount(response.total);
                    setVaultListCounts(response.lists)
                }
            })
            .catch((error) => setVaultError(error))
            .finally(() => setVaultLoading(false))

    }, [vaultIDs]);

    return { vaultCount, vaultListCounts, vaultLoading, vaultError };
};
