import React, { useEffect, useState } from 'react';
import { Geography, QueryItem } from '../models/DataSource';
import { Storefront } from '../models/StorefrontResponse';
import { Box, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material';
import geographyData from '../assets/data/geography.json';
import { useQueryCity } from '../hooks/useQueryCity';
import SearchIcon from '@mui/icons-material/Search';
import CitySearchDialog from './CitySearchDialog';
import { City } from '../models/City';

interface Props {
    queryItem: QueryItem;
    storefront: Storefront
    updateValue: (value: string) => void;
}

function defaultValue(queryItem: QueryItem): string[] {
    switch (queryItem.field.key) {
        case "secondary_state_countycode":
            if (queryItem.filter.values.length > 0 && queryItem.filter.values[0] != "") {
                return queryItem.filter.values
            } else {
                return ["AK"]
            }
        case "state_countycode":
            if (queryItem.filter.values.length > 0 && queryItem.filter.values[0] != "") {
                return queryItem.filter.values
            } else {
                return ["AK"]
            }
        case "state":
            return ["AK"]
        case "secondary_state":
            return ["AK"]
        case "scf":
            return ["005"]
        default:
            break;
    }

    return []
}

const QueryFieldCustom = (props: Props) => {
    const [counties, setCounties] = useState<{ [key: string]: string }>({})
    const [geography, setGeography] = useState<Geography>({ state: {}, county: {}, metro_area: {}, scf: {} });
    const [selectValue, setSelectValue] = useState<string[]>(defaultValue(props.queryItem));
    const [selectedState, setSelectedState] = useState<string>("AK");
    const [cityStateID, setCityStateID] = useState<number>(0)
    const [openSearchCityDialog, setOpenSearchCityDialog] = useState(false);
    const [cityStateName, setCityStateName] = useState<string>("")
    const { city, cityLoading } = useQueryCity(cityStateID);

    useEffect(() => {
        if (props.queryItem.field.key === 'city_state') {
            setCityStateID(props.queryItem.filter.values.length > 0 ? parseInt(props.queryItem.filter.values[0]) : 0)
        }
    }, [props.queryItem.field.key])

    useEffect(() => {
        if (city.id) {
            setCityStateName(city.city_state)
        }
    }, [city])

    useEffect(() => {
        // Load geography data 
        setGeography(geographyData as Geography);
        if (props.queryItem.field.key === "secondary_state_countycode" || props.queryItem.field.key === "state_countycode" || props.queryItem.field.key === "state" || props.queryItem.field.key === "secondary_state" || props.queryItem.field.key === "scf") {
            switch (props.queryItem.field.key) {
                case "secondary_state_countycode":
                    if (props.queryItem.filter.values.length > 0 && props.queryItem.filter.values[0] != "") {
                        const st = props.queryItem.filter.values[0].substring(0, 2)
                        setSelectedState(st)
                        configureCounties(st)
                        setSelectValue(props.queryItem.filter.values)
                    } else {
                        setSelectedState("AK");
                        configureCounties("AK")
                    }
                    break;
                case "state_countycode":
                    if (props.queryItem.filter.values.length > 0 && props.queryItem.filter.values[0] != "") {
                        const st = props.queryItem.filter.values[0].substring(0, 2)
                        setSelectedState(st)
                        configureCounties(st)
                        setSelectValue(props.queryItem.filter.values)
                    } else {
                        setSelectedState("AK");
                        configureCounties("AK")
                    }
                    break;
                case "state":
                    setSelectValue(["AK"]);
                    break;
                case "secondary_state":
                    setSelectValue(["AK"]);
                    break;
                case "scf":
                    setSelectValue(["005"]);
                    break;
                default:
                    break;
            }

        }
    }, [props.queryItem.field.key])

    const handleSelectValueChange = (event: SelectChangeEvent<typeof selectValue>) => {
        const { target: { value }, } = event;
        setSelectValue(typeof value === 'string' ? value.split(',') : value);
        console.log("Selected Values:", typeof value === 'string' ? value.split(',') : value);
        props.updateValue(value as string);
    }

    const handleStateValueChange = (event: SelectChangeEvent) => {
        const st = event.target.value as string
        setSelectedState(st)

        configureCounties(st)
    }

    const configureCounties = (st: string) => {
        // Iterate over geography.county and only include ones where the state matches
        const filteredCounties = Object.entries(geography.county).filter(([, value]) => {
            return value.startsWith(st);
        });

        // Convert the filtered entries back to an object with the same type as geography.county
        const filteredCountyObject = filteredCounties.reduce((acc, [key, value]) => {
            acc[key] = value.substring(3);
            return acc;
        }, {} as { [key: string]: string });

        setCounties(filteredCountyObject)
    }

    const searchCityButtonClicked = () => {
        setOpenSearchCityDialog(true);
    }

    const handleCloseCitySearchDialog = () => {
        setOpenSearchCityDialog(false);
    }

    const handleCitySelected = (city: City) => {
        props.updateValue(city.id.toString());
        setSelectValue([city.id.toString()]);
        setCityStateName(city.city_state);
        console.log("QFC: City Selected:", city)
        setOpenSearchCityDialog(false);
    }

    const dialogStyle = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: props.storefront.activeColors.mainPage.headerBackground,
        border: '1px solid #5c5c5e',
        boxShadow: 24,
        p: 1,
    };

    return (
        <Box>
            {props.queryItem.field.key === 'state_countycode' || props.queryItem.field.key === 'secondary_state_countycode'
                ? <Stack spacing={1}>
                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                        <InputLabel>State</InputLabel>
                        <Select label="Values" onChange={handleStateValueChange} size="small" value={selectedState} MenuProps={{
                            sx: {
                                "&& .Mui-selected": {
                                    backgroundColor: props.storefront.activeColors.leftMenu.icon
                                }
                            }
                        }}>
                            {Object.entries(geography.state).map(([key, value]) => (
                                <MenuItem value={key} key={key}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                        <InputLabel>County</InputLabel>
                        <Select label="Values" multiple onChange={handleSelectValueChange} size="small" value={selectValue} MenuProps={{
                            sx: {
                                "&& .Mui-selected": {
                                    backgroundColor: props.storefront.activeColors.leftMenu.icon
                                }
                            }
                        }}>
                            {Object.entries(counties).map(([key, value]) => (
                                <MenuItem value={key} key={key}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                : props.queryItem.field.key === 'state'
                    ? <FormControl fullWidth size="small" sx={{ width: 300 }}>
                        <InputLabel>Values</InputLabel>
                        <Select label="Values" multiple onChange={handleSelectValueChange} size="small" value={selectValue} MenuProps={{
                            sx: {
                                "&& .Mui-selected": {
                                    backgroundColor: props.storefront.activeColors.leftMenu.icon
                                }
                            }
                        }}>
                            {Object.entries(geography.state).map(([key, value]) => (
                                <MenuItem value={key} key={key}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    : props.queryItem.field.key === 'secondary_state'
                        ? <FormControl fullWidth size="small" sx={{ width: 300 }}>
                            <InputLabel>Values</InputLabel>
                            <Select label="Values" multiple onChange={handleSelectValueChange} size="small" value={selectValue} MenuProps={{
                                sx: {
                                    "&& .Mui-selected": {
                                        backgroundColor: props.storefront.activeColors.leftMenu.icon
                                    }
                                }
                            }}>

                                {Object.entries(geography.state).map(([key, value]) => (
                                    <MenuItem value={key} key={key}>{value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        : props.queryItem.field.key === 'scf'
                            ? <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                <InputLabel>Values</InputLabel>
                                <Select label="Values" onChange={handleSelectValueChange} multiple size="small" value={selectValue} MenuProps={{
                                    sx: {
                                        "&& .Mui-selected": {
                                            backgroundColor: props.storefront.activeColors.leftMenu.icon
                                        }
                                    }
                                }}>
                                    {Object.entries(geography.scf).map(([key, value]) => (
                                        <MenuItem value={key} key={key}>{value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            : props.queryItem.field.key === 'city_state' || props.queryItem.field.key === 'secondary_city_state'
                                ? <Stack direction="row" spacing={1}>
                                    <FormControl fullWidth size="small" sx={{ width: 260 }}>
                                        <TextField fullWidth size="small" sx={{ width: 260 }} label="City/State" variant="outlined" disabled={true} value={cityStateName} />
                                    </FormControl>
                                    {cityLoading
                                        ? <CircularProgress size="20px" />
                                        : <IconButton sx={{ color: props.storefront.activeColors.addProductForm.iconForeground }} size="small" type="button" onClick={searchCityButtonClicked} >
                                            <SearchIcon />
                                        </IconButton>
                                    }
                                </Stack>
                                : <Typography>Unknown Field</Typography>
            }
            <Modal
                open={openSearchCityDialog}
                onClose={handleCloseCitySearchDialog}
            >
                <Box sx={dialogStyle}>
                    <CitySearchDialog closeDialogCallback={handleCloseCitySearchDialog} citySelectedCallback={handleCitySelected} city={city.city_state} storefront={props.storefront} />
                </Box>
            </Modal>
        </Box>

    )
}

export default QueryFieldCustom;