import { useState, useEffect } from 'react';
import { APIEndpoint, EndpointType, httpGet } from '../utils/apiService'
import { Audience, AudienceResponse } from '../models/Audience';

const apiURL = APIEndpoint(EndpointType.Audiences);

export const useAudiences = (audienceIDs: string[]) => {
    const [audiences, setAudiences] = useState<Audience[]>()
    const [audiencesError, setAudiencesError] = useState("")
    const [audiencesLoading, setAudiencesLoading] = useState(false)

    useEffect(() => {
       if (!audienceIDs || audienceIDs === undefined || audienceIDs == null || audienceIDs.length == 0) {
            console.log("audienceids-undefined check...aborting useAudiences()", audienceIDs)
            return
        }

        setAudiencesLoading(true)
        setAudiencesError("")
        setAudiences([])

        httpGet(apiURL + "?ids=" + audienceIDs.join(","))
            .then((data) => {
                const response = data as AudienceResponse;

                if (response.status == "error") {
                    setAudiencesError(response.errorMessage)
                } else {
                    setAudiences(response.audiences)
                }
            })
            .catch((error) => setAudiencesError(error))
            .finally(() => setAudiencesLoading(false))

    }, [audienceIDs]);

    return { audiences, audiencesLoading, audiencesError };
};